import React from 'react'
import { Icon, Input, Modal, notification } from 'antd'
import uuid from 'utils/uuid'

export const requirePassword = () =>
  new Promise((accept, reject) => {
    const key = uuid()
    let passwordNode

    return Modal.confirm({
      content: (
        <div>
          <h5>Your password is required to continue</h5>
          <Input ref={node => passwordNode = node} prefix={<Icon type='lock' />} type='password' />
        </div>
      ),
      key,
      title: 'Authentication Required',
      type: 'error',
      icon: <Icon type='lock' />,
      className: 'require-password-notification',
      onOk() {
        Modal.destroyAll()
        return accept(passwordNode.state.value)
      },
      onCancel() {
        Modal.destroyAll()
        return reject()
      },
    })
  })

export const confirm = (content, title = 'Are you sure?') =>
  new Promise((accept, reject) => {
    const key = uuid()
    return Modal.confirm({
      content,
      key,
      title,
      className: 'confirm-notification',
      onOk() {
        Modal.destroyAll()
        return accept()
      },
      onCancel() {
        Modal.destroyAll()
        return reject()
      },
    })
  })

export const countdown = (content, numSeconds = 5, title = 'Success', type = 'success') => {
  return new Promise((accept, reject) => {
    const key = uuid()
    const modal = Modal[type]({
      content: content.replace('%s', numSeconds),
      key,
      title,
      className: 'countdown-notification',
    })

    const timer = setInterval(() => {
      numSeconds -= 1;
      modal.update({
        content: content.replace('%s', numSeconds),
      });
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
      accept()
    }, numSeconds * 1000);

    return modal
  })
}

export const countdownError = (content, numSeconds = 5, title = 'Error') =>
  countdown(content, numSeconds, title, 'error')

export const info = (description, title = 'Info') =>
  setTimeout(() =>
    notification.info({
      message: title,
      description,
      className: 'info-notification',
      // duration: 900000, // turn on when styling
    }))

export const error = (description, title = 'Error') =>
  setTimeout(() =>
    notification.error({
      message: title,
      description,
      className: 'error-notification',
      // duration: 900000, // turn on when styling
    }))

export const success = (description, title = 'Success') =>
  setTimeout(() =>
    notification.success({
      message: title,
      description,
      className: 'success-notification',
      // duration: 900000, // turn on when styling
    }))

export const warning = (description, title = 'Warning') =>
  setTimeout(() =>
    notification.warning({
      message: title,
      description,
      className: 'warning-notification',
      // duration: 900000, // turn on when styling
    }))

export default {
  confirm,
  countdown,
  countdownError,
  error,
  info,
  requirePassword,
  success,
  warning,
}
