import {
  AUTH_REQUIRE_PASSWORD_ACTION,
  LOGIN_ACTION,
  LOGOUT_ACTION,
  USER_UPDATE_ACTION,
  USER_PASSWORD_UPDATE_ACTION,
} from 'config/constants'

export const login = (email, password) => ({
  type: LOGIN_ACTION,
  payload: { email, password },
})

export const logout = () => ({
  type: LOGOUT_ACTION,
  payload: null,
})

export const requirePassword = key => ({
  type: AUTH_REQUIRE_PASSWORD_ACTION,
  payload: key,
})

export const updatePassword = password => ({
  type: USER_PASSWORD_UPDATE_ACTION,
  payload: password,
})

export const updateUser = (data, persist = false) => ({
  type: USER_UPDATE_ACTION,
  payload: data,
  persist,
})

export default {
  login,
  logout,
  requirePassword,
  updatePassword,
  updateUser,
}
