import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AudioActions from 'actions/Audio'
import {
  PlayButton,
  Progress,
  Timer,
} from 'react-soundplayer/components'

import './Player.scss'

const mapStateToProps = state => ({
  showPlayer: state.Audio.showPlayer,
  playlist: state.Audio.playlist,
  soundCloudAudio: state.Audio.soundCloudAudio,
})

const mapDispatchToProps = dispatch => ({
  pause: () => dispatch(AudioActions.pause()),
})

class Player extends React.Component {
  state = {
    hasAudioEvents: false,
    currentTime: 0,
  }

  bindAudioEvents = () => {
    const { soundCloudAudio } = this.props
    const { hasAudioEvents } = this.state

    if ( ! soundCloudAudio) return false
    if (hasAudioEvents) return false

    this.setState({ hasAudioEvents: true })

    soundCloudAudio.on('timeupdate', (e) => {
      const { currentTime, duration } = soundCloudAudio.audio
      this.setState({ currentTime })
    })
  }

  componentWillUnmount() {
    const { soundCloudAudio } = this.props
    const { hasAudioEvents } = this.state

    if ( ! soundCloudAudio) return false
    if ( ! hasAudioEvents) return false

    soundCloudAudio.off('timeupdate')
  }

  render() {
    if ( ! this.props.showPlayer) return false

    const { pause, playlist, soundCloudAudio } = this.props
    const { currentTime } = this.state
    const { duration } = soundCloudAudio.audio

    this.bindAudioEvents()

    return (
      <div id='player'>
        <div className="p2">
          <div className="progress-wrap">
            <div className='button-container'>
              <PlayButton
                playing={true}
                className="flex-none button button-transparent button-grow rounded"
                soundCloudAudio={soundCloudAudio}
                onTogglePlay={pause}
                {...this.props} />
            </div>
            <Progress
              className="mt1 mb1"
              value={(currentTime / duration) * 100 || 0}
              {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Player)
