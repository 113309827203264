import { call, put, takeLatest, select } from 'redux-saga/effects'

import {
  AUDIO_PLAY_TRACK_ACTION,
  AUDIO_PLAY_NEXT_TRACK_ACTION,
} from 'config/constants'

const onSongEnd = async (soundCloudAudio) => {
  return new Promise(accept => {
    soundCloudAudio.off('ended', accept)

    soundCloudAudio.on('ended', function() {
      accept()
    })
  })
}

function* setNextSongSaga() {
  const soundCloudAudio = yield select(state => state.Audio.soundCloudAudio)
  yield onSongEnd(soundCloudAudio)
  yield put({ type: AUDIO_PLAY_NEXT_TRACK_ACTION })
  yield setNextSongSaga()
}

export default function* () {
  yield takeLatest(AUDIO_PLAY_TRACK_ACTION, setNextSongSaga)
}
