import isEqual from 'lodash/isEqual'

import notify from 'utils/notify'
import {
  USER_GET_AUTHED_SUCCEEDED_ACTION,
  USER_UPDATE_ACTION,
  USER_UPDATE_SUCCEEDED_ACTION,
} from 'config/constants'

const initialState = {
  userChanged: false,
  user: {
    name: '',
    email: '',
    licenses: [],
    films: [],
    preferences: [],
    royalties: [],
  },
  userLock: null, // loads when authed user is fetched
}

export default function(state = initialState, action) {
  switch (action.type) {

    case USER_GET_AUTHED_SUCCEEDED_ACTION:
      const fetchedUser = { ...state.user, ...action.payload.user }
      return {
        ...state,
        ...{ user: fetchedUser },
        ...{ userLock: action.payload.user }
      }

    case USER_UPDATE_ACTION:
      const updatedUser = { ...state.user, ...action.payload }
      return {
        ...state,
        ...{
          user: updatedUser,
          userChanged: ! isEqual(updatedUser, state.userLock),
        }
      }

    case USER_UPDATE_SUCCEEDED_ACTION:
      notify.success('Successfully updated your preferences')
      return {
        ...state,
        ...{ userLock: state.user, userChanged: false },
      }

    default:
      return state
  }
}
