import {
  AUTH_CHECK_ACTION,
  REDIRECT_ACTION,
  USER_GET_AUTHED_ACTION,
} from 'config/constants'

export const redirect = url => ({
  type: REDIRECT_ACTION,
  payload: url,
})

export const authCheck = () => ({
  type: AUTH_CHECK_ACTION,
  payload: null,
})

export const getAuthedUser = () => ({
  type: USER_GET_AUTHED_ACTION,
  payload: null,
})

export default {
  authCheck,
  getAuthedUser,
  redirect,
}
