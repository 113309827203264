import concat from 'lodash/concat'

export const routes = {
  authed: {
    main: { name: 'Main', path: '/' },
    dashboard: { name: 'Dashboard', path: '/dashboard' },
    films: { name: 'Films', path: '/films' },
    preferences: { name: 'Preferences', path: '/preferences' },
  },
  unauthed: {
    login: { name: 'Login', path: '/login' },
    logout: { name: 'Logout', path: '/logout' },
  }
}

export const allRoutes = () => concat(authedRoutes(), unauthedRoutes())
export const authedRoutes = () => Object.keys(routes.authed).map(key => routes.authed[key])
export const unauthedRoutes = () => Object.keys(routes.unauthed).map(key => routes.unauthed[key])
export const isAuthedRoute = route => isRoute(route, true)
export const isUnauthedRoute = route => isRoute(route, false)
export const isRoute = (path, useAuthed = true) => {
  const route = (useAuthed ? authedRoutes() : unauthedRoutes())
    .find(route => route.path === (path || window.location.pathname))

  return !! route
}

export default routes
