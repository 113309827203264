import uuid from 'utils/uuid'
import MOCK_USER from 'api/stubs/user'

const MOCK_PASS = 'mindmeadows'

const cachePassword = password => {
  localStorage.setItem('auth_user_password', password)
}

const getCachedPassword = () => {
  const password = localStorage.getItem('auth_user_password')
  return password || MOCK_PASS
}

const getSetAuthedUser = () => {
  const json = localStorage.getItem('authed_user_json')
  if (json) return JSON.parse(json)

  localStorage.setItem('authed_user_json', JSON.stringify(MOCK_USER))
  return getSetAuthedUser()
}

const upsertAuthedUser = (data) => {
  const user = getSetAuthedUser()
  const newUser = { ...user, ...data }

  localStorage.setItem('authed_user_json', JSON.stringify(newUser))
  return newUser
}

export const login = (email, password) => {
  return new Promise((accept, reject) => {
    if (password === getCachedPassword()) {
      accept(uuid())
    } else {
      reject('invalid credentials')
    }
  })
}

export const updateUser = data => {
  return new Promise((accept, reject) => {
    accept(upsertAuthedUser(data))
  })
}

export const updateUserPassword = password => {
  return new Promise((accept, reject) => {
    cachePassword(password)
    return accept()
  })
}

export const getAuthedUser = () => {
  return new Promise((accept, reject) => {
    return accept({ user: getSetAuthedUser() })
    // return reject(new Error('FAIL'))
  })
}

export const logout = (email, password) => {
  localStorage.removeItem('auth_token')
  window.location.href = '/login'
}

export const verifyPassword = password => {
  return new Promise((accept, reject) => {
    if (password === getCachedPassword()) {
      accept()
    } else {
      reject('invalid credentials')
    }
  })
}

export default {
  getAuthedUser,
  login,
  logout,
  updateUser,
  updateUserPassword,
  verifyPassword,
}
