import {
  INSTAGRAM_RECENT_FAILED_ACTION,
  INSTAGRAM_RECENT_SUCCEEDED_ACTION
} from 'config/constants'

const initialState = {
  posts: []
}

export default function(state = initialState, action) {
  switch (action.type) {

    case INSTAGRAM_RECENT_FAILED_ACTION:
      return state

    case INSTAGRAM_RECENT_SUCCEEDED_ACTION:
      return { ...state, ...{ posts: action.payload }}

    default:
      return state
  }
}
