import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Icon } from 'antd'

import InstagramActions from 'actions/Instagram'
import Controller from 'controllers/Controller'
import Song from 'components/Song'
import Video from 'components/Video'
import { withSoundCloudAudio } from 'react-soundplayer/addons'

import 'controllers/Main.scss'

const pointsOfContact = [
  {
    name: <span><Icon type="instagram" /> instagram</span>,
    url: 'https://www.instagram.com/sunhatcymbals',
  },
  {
    name: <span><Icon type="mail" /> email</span>,
    url: 'mailto:elliemaemusic@gmail.com',
  }
]

const mapStateToProps = state => ({
  showSecondarySidebar: state.UI.showSecondarySidebar,
  windowHeight: state.UI.dimensions.height,
  windowWidth: state.UI.dimensions.width,
  scrollTop: state.UI.scrollTop,
  posts: state.Instagram.posts,
})

const mapDispatchToProps = dispatch => ({
  instagramRecent: () => dispatch(InstagramActions.recent()),
})

class Main extends Controller {
  render() {
    const {
      playlist,
      soundCloudAudio,
    } = this.props

    return (
      <div id='main-controller'>

        <div className='section hero'>
          <div className='background-image'></div>
          <div className='short-bio'>
            <p>Slumbering legs race down suburb streets</p>
            <p>Where memories complain</p>
          </div>
        </div>

        <div className='section playlist-container'>
          <div className='album-title'>Mind Meadows</div>
          <div className='playlist'>
            <div className='album-cover'></div>
            <div className='songs'>
              { playlist && playlist.tracks.map((track, i) =>
                <Song
                  key={ i }
                  position={ i + 1 }
                  track={ track }
                  soundCloudAudio={ soundCloudAudio } />
              ) }
            </div>
          </div>
        </div>

        <div className='section video-section'>
          <Video />
        </div>

        <div className='section social'>
          <div className='inner-container'>
            <ul className='contacts'>
              { pointsOfContact.map( (contact, key) =>
                <li className='contact' key={ key }>
                  <a href={ contact.url }>{ contact.name }</a>
                </li>
              ) }
            </ul>
          </div>
        </div>

      </div>
    )
  }

  static propTypes = {
    resolveUrl: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSoundCloudAudio(Main));
