import {
  REDIRECT_ACTION,
  AUTH_CHECK_ACTION,
} from 'config/constants'

const initialState = {
  redirect: null,
  tokenFound: false,
}

export default function(state = initialState, action) {
  switch (action.type) {

    case REDIRECT_ACTION:
      return {
        ...state,
        redirect: action.payload,
      }

    case AUTH_CHECK_ACTION:
      return {
        ...state,
        tokenFound: !!localStorage.getItem('auth_token'),
      }

    default:
      return state
  }
}
