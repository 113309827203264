import {
  AUTH_REQUIRE_PASSWORD_FAILED_ACTION,
  AUTH_REQUIRE_PASSWORD_SUCCEEDED_ACTION,
  PREFERENCES_CHANGE_PASSWORD,
  USER_PASSWORD_UPDATE_SUCCEEDED_ACTION,
} from 'config/constants'

import notify from 'utils/notify'

const initialState = {
  permitPasswordChange: false,
}

export default function(state = initialState, action) {
  switch (action.type) {

    case AUTH_REQUIRE_PASSWORD_FAILED_ACTION:
      notify.error('Failed to Authenticate')
      return state

    case AUTH_REQUIRE_PASSWORD_SUCCEEDED_ACTION:
      if (action.payload === PREFERENCES_CHANGE_PASSWORD) {
        return {
          ...state,
          permitPasswordChange: true,
        }
      }

      return state

  case USER_PASSWORD_UPDATE_SUCCEEDED_ACTION:
    return {
      ...state,
      permitPasswordChange: false,
    }

    default:
      return state
  }
}
