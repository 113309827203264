import {
  FILM_GET_FAILED_ACTION,
  FILM_GET_SUCCEEDED_ACTION
} from 'config/constants'

const initialState = {
  films: []
}

export default function(state = initialState, action) {
  switch (action.type) {

    case FILM_GET_FAILED_ACTION:

      return state

    case FILM_GET_SUCCEEDED_ACTION:
      return { ...state, ...{ films: action.payload }}

    default:
      return state
  }
}
