import {
  UI_SHOW_SECONDARY_SIDEBAR_ACTION,
  UI_HIDE_SECONDARY_SIDEBAR_ACTION,
  UI_SET_DIMENSIONS_ACTION,
  UI_SET_SCROLL_TOP_ACTION,
  UI_SET_THEME_ACTION,
} from 'config/constants'

export const showSecondarySidebar = mode => ({
  type: UI_SHOW_SECONDARY_SIDEBAR_ACTION,
  payload: { mode },
})

export const hideSecondarySidebar = mode => ({
  type: UI_HIDE_SECONDARY_SIDEBAR_ACTION,
  payload: null,
})

export const setTheme = theme => ({
  type: UI_SET_THEME_ACTION,
  payload: theme,
})

export const updateDimensions = (width, height) => ({
  type: UI_SET_DIMENSIONS_ACTION,
  payload: { width, height },
})

export const updateScrollTop = top => ({
  type: UI_SET_SCROLL_TOP_ACTION,
  payload: top,
})

export default {
  hideSecondarySidebar,
  setTheme,
  showSecondarySidebar,
  updateDimensions,
  updateScrollTop,
}
