import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import 'whatwg-fetch' // fetch polyfill
import { withSoundCloudAudio } from 'react-soundplayer/addons'

import 'antd/dist/antd.css'
import './App.scss'
import 'react-soundcloud.css'
import 'react-soundcloud-overrides.css'
import './favicon.ico'

import AppActions from 'actions/App'
import UIActions from 'actions/UI'
import AudioActions from 'actions/Audio'
import Main from 'controllers/Main'
import Login from 'controllers/Login'
import Logout from 'controllers/Logout'

import Player from 'components/Player'

const { Content } = Layout

const mapStateToProps = state => ({
  ...state.App,
  playing: state.Audio.playing,
  playlistLoaded: !!state.Audio.playlist,
  soundCloudAudioLoaded: !!state.Audio.soundCloudAudio,
  showSecondarySidebar: state.UI.showSecondarySidebar,
  theme: state.UI.theme,
  dimensions: state.UI.dimensions,
})

const mapDispatchToProps = dispatch => ({
  authCheck: () => dispatch(AppActions.authCheck()),
  getAuthedUser: () => dispatch(AppActions.getAuthedUser()),
  loadPlaylist: playlist => dispatch(AudioActions.loadPlaylist(playlist)),
  soundCloudInitialized: soundCloudAudio => dispatch(AudioActions.soundCloudInitialized(soundCloudAudio)),
  unsetRedirect: () => dispatch(AppActions.redirect(null)),
  updateDimensions: () => dispatch(UIActions.updateDimensions(window.innerWidth, window.innerHeight)),
  updateScrollTop: e => dispatch(UIActions.updateScrollTop(e.target.scrollTop)),
})

class App extends React.Component {
  componentDidMount() {
    const { authCheck, getAuthedUser, updateDimensions } = this.props

    authCheck()
    getAuthedUser()
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
  }

  componentWillUnmount() {
    const { updateDimensions } = this.props
    window.removeEventListener('resize', updateDimensions);
  }

  getLayoutMargin = () => {
    return 0
  }

  render() {
    const {
      loadPlaylist,
      playing,
      playlist,
      playlistLoaded,
      redirect,
      soundCloudAudio,
      soundCloudAudioLoaded,
      soundCloudInitialized,
      theme,
      tokenFound,
      unsetRedirect,
      updateScrollTop,
    } = this.props

    if ( ! playlistLoaded && playlist) {
      loadPlaylist(playlist)
    }

    if ( ! soundCloudAudioLoaded && soundCloudAudio) {
      soundCloudInitialized(soundCloudAudio)
    }

    if (redirect) {
      unsetRedirect()
      return <Redirect to={ redirect } />
    }

    if ( ! tokenFound) {
      return (
        <div id="routes" className="unauthed">
          <Switch>
            <Route path="/login" exact component={ Login } />
            <Route path="/logout" exact component={ Logout } />
            <Redirect to="/login" />
          </Switch>
        </div>
      )
    }

    return (
      <Layout
        id='app-main-layout'
        data-theme={ theme }
        className={`${playing ? 'playing' : ''}`}
      >
        <Layout style={{ marginLeft: this.getLayoutMargin() }}>
          <Content style={{ overflow: 'initial' }}>
            <div id="routes">
              <div onScroll={ updateScrollTop } id='scroll-container'>
                <Switch>
                  <Route path="/" exact>
                    <Main
                      clientId={ this.props.clientId }
                      resolveUrl={ this.props.resolveUrl }
                    />
                  </Route>
                  <Route path="/logout" exact component={ Logout } />
                  <Redirect to="/" />
                </Switch>
              </div>
              <Player />
            </div>
          </Content>
        </Layout>
      </Layout>
    )
  }

  static propTypes = {
    resolveUrl: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSoundCloudAudio(App)))
