import React from 'react'
import { connect } from 'react-redux'

import AuthActions from 'actions/Auth'

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    logout: (email, password) => {
      dispatch(AuthActions.logout())
    },
  }
}

class Login extends React.Component {
  componentDidMount() {
    const { logout } = this.props
    logout()
  }

  render() {
    return <div></div>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
