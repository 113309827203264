import films from 'api/stubs/films.js'



export const get = () => {
  return new Promise((accept, reject) => {

    return accept({ films })
  })
}

export default {
  get,
}
