// import { get } from './common'
import jsonp from 'jsonp'
// const userId = 1647892174
const userId = 'self'
// https://api.instagram.com/oauth/authorize/?client_id=64797d6b34be405f91bdfa17fe3c27fe&redirect_uri=http://localhost:3000&response_type=token
const access_token = '1647892174.64797d6.ae719f98a1db4abf88ed676f492b7810'

export const recent = () => {
  return new Promise((accept, reject) => {
    return jsonp(`https://api.instagram.com/v1/users/${ userId }/media/recent?access_token=${ access_token }`, null, (err, data) => {
      if (err) return reject(err)
      return accept(data.data)
    })
  })
}

export default {
  recent
}
