import { call, put, takeLatest } from 'redux-saga/effects'

import FilmAPI from 'api/mock/film'
import {
  FILM_GET_ACTION,
  FILM_GET_FAILED_ACTION,
  FILM_GET_SUCCEEDED_ACTION,

} from 'config/constants'

function* filmsSaga(action) {
  try {
    const result = yield call(FilmAPI.get)
    yield put({ type: FILM_GET_SUCCEEDED_ACTION, payload: result.films })
  } catch (e) {
    yield put({ type: FILM_GET_FAILED_ACTION, payload: e.message });
  }
}


function* filmSaga() {
  yield takeLatest(FILM_GET_ACTION, filmsSaga);
}

export default filmSaga;
