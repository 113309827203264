import React from 'react'
import { Icon } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withSoundCloudAudio } from 'react-soundplayer/addons'

import AudioActions from 'actions/Audio'

import './Song.scss'

const mapStateToProps = state => ({
  currentTrackId: state.Audio.trackId,
})

const mapDispatchToProps = dispatch => ({
  playTrack: trackId => dispatch(AudioActions.playTrack(trackId)),
  pauseTrack: trackId => dispatch(AudioActions.pause()),
})

class Song extends React.Component {
  render() {
    const {
      currentTrackId,
      playTrack,
      pauseTrack,
      track,
      position,
    } = this.props

    const isPlaying = currentTrackId === track.id
    const iconType = isPlaying ? 'pause' : 'caret-right'

    return (
      <div
        onClick={ () => {
          isPlaying ?
            pauseTrack(track.id) :
            playTrack(track.id)
        }}
        className='song'
      >
        <Icon type={ iconType } />
        <div className='title'>{ position }. { track.title }</div>
      </div>
    )
  }

  static propTypes = {
    soundCloudAudio: PropTypes.object,
    track: PropTypes.object,
    position: PropTypes.number,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSoundCloudAudio(Song))
