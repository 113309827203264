import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import Store from 'Store'

class Root extends React.Component {
  render() {
    return (
      <div id="app-root">
        <Provider store={ Store }>
          <div id="app">
            <Router>
              { this.props.children }
            </Router>
          </div>
        </Provider>
      </div>
    )
  }
}

export default Root;
