export default [
  {
    "propno": "ATTACKOF50",
    "territorycd": "USM",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "CAN",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "UK",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "FRMET",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "GER",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "ITALY",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "SPN",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "BENELUX",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "SCAN",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "ICE",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "SWISS",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "GRC",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "POR",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "AUST",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "NZ",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "JAP",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "SOKO",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "CAM",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "CHINA",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "HKG",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "MONGOLIA",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "INDNSA",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "MAL",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "PHIL",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "SING",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "TWN",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "THAI",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "VIET",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "IND",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "BUR",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "PAK",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "SRI",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "MEX",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "BRZ",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "WIND",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "LA",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "CIS",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "UKR",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "BAL",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "POL",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "CZECH",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "SLOV",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "HUNG",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "ALB",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "CROAT",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "SERB",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "SLV",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "ROM",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "BUL",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "ISR",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "MEMIP",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "TUR",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "ATTACKOF50",
    "territorycd": "REPSOAFR",
    "propname": "Attack of the 50 Foot Woman",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Nathan Juran",
    "directors_len": 25,
    "directors": "Director(s): Nathan Juran",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Nathan Juran\\x0a",
    "actors": "Allison Hayes",
    "genre": "William Hudson",
    "right1": "Yvette Vickers",
    "right2": "Roy Gordon",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When an abused socialite grows to giant size because of an alien encounter and an aborted murder attempt",
    "websiteurl": "she goes after her cheating husband with revenge on her mind.",
    "releaseyear": "Attackofthe50FootWoman_thumb.jpg",
    "notes": "133 mins"
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "USM",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "CAN",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "UK",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "FRMET",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "GER",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "ITALY",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "SPN",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "BENELUX",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "SCAN",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "Sold",
    "right7": "Sold",
    "right8": "Sold",
    "right9": "Sold",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "ICE",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "SWISS",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "GRC",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "POR",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "AUST",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "NZ",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "JAP",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "SOKO",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "CAM",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "CHINA",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "HKG",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "MONGOLIA",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "INDNSA",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "MAL",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "PHIL",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "SING",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "TWN",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "THAI",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "VIET",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "IND",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "BUR",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "PAK",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "SRI",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "BRZ",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "MEX",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "WIND",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "LA",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "CIS",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "UKR",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "BAL",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "POL",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "CZECH",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "SLOV",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "HUNG",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "ALB",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "CROAT",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "SERB",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "SLV",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "ROM",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "BUL",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "ISR",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "MEMIP",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "TUR",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "CREATUREFROMB",
    "territorycd": "REPSOAFR",
    "propname": "Creature from the Black Lagoon",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "Yes",
    "production_dates": "2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Julie Adams",
    "right1": "Richard Denning",
    "right2": "Antonio Moreno",
    "right3": "Action",
    "right4": "Science Fiction",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A strange prehistoric beast lurks in the depths of the Amazonian jungle. A group of scientists try to capture the animal and bring it back to civilization for study.",
    "releaseyear": "CreaturefromtheBlackLagoon_thumb.jpg",
    "notes": ""
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "USM",
    "propname": "Destination Moon ",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "CAN",
    "propname": "Destination Moon ",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "UK",
    "propname": "Destination Moon ",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "FRMET",
    "propname": "Destination Moon ",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "GER",
    "propname": "Destination Moon ",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "ITALY",
    "propname": "Destination Moon ",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "SPN",
    "propname": "Destination Moon ",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "BENELUX",
    "propname": "Destination Moon ",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "SCAN",
    "propname": "Destination Moon ",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "ICE",
    "propname": "Destination Moon ",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "SWISS",
    "propname": "Destination Moon ",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "GRC",
    "propname": "Destination Moon ",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "POR",
    "propname": "Destination Moon ",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "AUST",
    "propname": "Destination Moon ",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "NZ",
    "propname": "Destination Moon ",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "JAP",
    "propname": "Destination Moon ",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "SOKO",
    "propname": "Destination Moon ",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "CAM",
    "propname": "Destination Moon ",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "CHINA",
    "propname": "Destination Moon ",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "HKG",
    "propname": "Destination Moon ",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "MONGOLIA",
    "propname": "Destination Moon ",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "INDNSA",
    "propname": "Destination Moon ",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "MAL",
    "propname": "Destination Moon ",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "PHIL",
    "propname": "Destination Moon ",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "SING",
    "propname": "Destination Moon ",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "TWN",
    "propname": "Destination Moon ",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "THAI",
    "propname": "Destination Moon ",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "VIET",
    "propname": "Destination Moon ",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "IND",
    "propname": "Destination Moon ",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "BUR",
    "propname": "Destination Moon ",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "PAK",
    "propname": "Destination Moon ",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "SRI",
    "propname": "Destination Moon ",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "MEX",
    "propname": "Destination Moon ",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "BRZ",
    "propname": "Destination Moon ",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "WIND",
    "propname": "Destination Moon ",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "LA",
    "propname": "Destination Moon ",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "CIS",
    "propname": "Destination Moon ",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "UKR",
    "propname": "Destination Moon ",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "BAL",
    "propname": "Destination Moon ",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "POL",
    "propname": "Destination Moon ",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "CZECH",
    "propname": "Destination Moon ",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "SLOV",
    "propname": "Destination Moon ",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "HUNG",
    "propname": "Destination Moon ",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "ALB",
    "propname": "Destination Moon ",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "CROAT",
    "propname": "Destination Moon ",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "SERB",
    "propname": "Destination Moon ",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "SLV",
    "propname": "Destination Moon ",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "ROM",
    "propname": "Destination Moon ",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "BUL",
    "propname": "Destination Moon ",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "ISR",
    "propname": "Destination Moon ",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "MEMIP",
    "propname": "Destination Moon ",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "TUR",
    "propname": "Destination Moon ",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DESTINATIONM",
    "territorycd": "REPSOAFR",
    "propname": "Destination Moon ",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "2012-2013",
    "directors_string": "Director(s): Irving Pichel",
    "directors_len": 26,
    "directors": "Director(s): Irving Pichel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Irving Pichel\\x0a",
    "actors": "John Archer",
    "genre": "Warner Anderson",
    "right1": "Tom Powers",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "One of the first science fiction films to attempt a high level of accurate technical detail tells the story of the first trip to the Moon.",
    "imdblink": "DestinationMoon_thumb.jpeg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0042393/"
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "USM",
    "propname": "Donovans Brain",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "CAN",
    "propname": "Donovans Brain",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "UK",
    "propname": "Donovans Brain",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "FRMET",
    "propname": "Donovans Brain",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "GER",
    "propname": "Donovans Brain",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "ITALY",
    "propname": "Donovans Brain",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "SPN",
    "propname": "Donovans Brain",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "BENELUX",
    "propname": "Donovans Brain",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "SCAN",
    "propname": "Donovans Brain",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "ICE",
    "propname": "Donovans Brain",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "SWISS",
    "propname": "Donovans Brain",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "GRC",
    "propname": "Donovans Brain",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "POR",
    "propname": "Donovans Brain",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "AUST",
    "propname": "Donovans Brain",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "NZ",
    "propname": "Donovans Brain",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "JAP",
    "propname": "Donovans Brain",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "SOKO",
    "propname": "Donovans Brain",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "CAM",
    "propname": "Donovans Brain",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "CHINA",
    "propname": "Donovans Brain",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "HKG",
    "propname": "Donovans Brain",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "MONGOLIA",
    "propname": "Donovans Brain",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "INDNSA",
    "propname": "Donovans Brain",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "MAL",
    "propname": "Donovans Brain",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "PHIL",
    "propname": "Donovans Brain",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "SING",
    "propname": "Donovans Brain",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "TWN",
    "propname": "Donovans Brain",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "THAI",
    "propname": "Donovans Brain",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "VIET",
    "propname": "Donovans Brain",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "IND",
    "propname": "Donovans Brain",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "BUR",
    "propname": "Donovans Brain",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "PAK",
    "propname": "Donovans Brain",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "SRI",
    "propname": "Donovans Brain",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "BRZ",
    "propname": "Donovans Brain",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "MEX",
    "propname": "Donovans Brain",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "WIND",
    "propname": "Donovans Brain",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "LA",
    "propname": "Donovans Brain",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "CIS",
    "propname": "Donovans Brain",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "UKR",
    "propname": "Donovans Brain",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "BAL",
    "propname": "Donovans Brain",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "POL",
    "propname": "Donovans Brain",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "CZECH",
    "propname": "Donovans Brain",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "SLOV",
    "propname": "Donovans Brain",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "HUNG",
    "propname": "Donovans Brain",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "ALB",
    "propname": "Donovans Brain",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "CROAT",
    "propname": "Donovans Brain",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "SERB",
    "propname": "Donovans Brain",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "SLV",
    "propname": "Donovans Brain",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "ROM",
    "propname": "Donovans Brain",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "BUL",
    "propname": "Donovans Brain",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "ISR",
    "propname": "Donovans Brain",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "MEMIP",
    "propname": "Donovans Brain",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "TUR",
    "propname": "Donovans Brain",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DONOVANSBR",
    "territorycd": "REPSOAFR",
    "propname": "Donovans Brain",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Felix E. Feist",
    "directors_len": 27,
    "directors": "Director(s): Felix E. Feist",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Felix E. Feist\\x0a",
    "actors": "Lew Ayres",
    "genre": "Gene Evans",
    "right1": "Nancy Reagan",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Yet another version of Curt Siodmaks novel about an honest scientist who keeps the brain of a ruthless dead millionaire (Donovan) alive in a tank. Donovan manages to impose his powerful will on the scientist",
    "imdblink": "and uses him to murder his enemies.",
    "websiteurl": "DonovansBrain_thumb.jpg",
    "releaseyear": "",
    "notes": ""
  },
  {
    "propno": "DRACULA",
    "territorycd": "USM",
    "propname": "Dracula",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "CAN",
    "propname": "Dracula",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "UK",
    "propname": "Dracula",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "FRMET",
    "propname": "Dracula",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "GER",
    "propname": "Dracula",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "ITALY",
    "propname": "Dracula",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "SPN",
    "propname": "Dracula",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "BENELUX",
    "propname": "Dracula",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "SCAN",
    "propname": "Dracula",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "ICE",
    "propname": "Dracula",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "SWISS",
    "propname": "Dracula",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "GRC",
    "propname": "Dracula",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "POR",
    "propname": "Dracula",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "AUST",
    "propname": "Dracula",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "NZ",
    "propname": "Dracula",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "JAP",
    "propname": "Dracula",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "SOKO",
    "propname": "Dracula",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "CAM",
    "propname": "Dracula",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "CHINA",
    "propname": "Dracula",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "HKG",
    "propname": "Dracula",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "MONGOLIA",
    "propname": "Dracula",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "INDNSA",
    "propname": "Dracula",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "MAL",
    "propname": "Dracula",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "PHIL",
    "propname": "Dracula",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "SING",
    "propname": "Dracula",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "TWN",
    "propname": "Dracula",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "THAI",
    "propname": "Dracula",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "VIET",
    "propname": "Dracula",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "IND",
    "propname": "Dracula",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "BUR",
    "propname": "Dracula",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "PAK",
    "propname": "Dracula",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "SRI",
    "propname": "Dracula",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "BRZ",
    "propname": "Dracula",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "MEX",
    "propname": "Dracula",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "WIND",
    "propname": "Dracula",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "LA",
    "propname": "Dracula",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "CIS",
    "propname": "Dracula",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "UKR",
    "propname": "Dracula",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "BAL",
    "propname": "Dracula",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "POL",
    "propname": "Dracula",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "CZECH",
    "propname": "Dracula",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "SLOV",
    "propname": "Dracula",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "HUNG",
    "propname": "Dracula",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "ALB",
    "propname": "Dracula",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "CROAT",
    "propname": "Dracula",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "SERB",
    "propname": "Dracula",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "SLV",
    "propname": "Dracula",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "ROM",
    "propname": "Dracula",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "BUL",
    "propname": "Dracula",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "ISR",
    "propname": "Dracula",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "MEMIP",
    "propname": "Dracula",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "TUR",
    "propname": "Dracula",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "DRACULA",
    "territorycd": "REPSOAFR",
    "propname": "Dracula",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): Tod Browning",
    "directors_len": 25,
    "directors": "Director(s): Tod Browning",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Tod Browning\\x0a",
    "actors": "Bela Lugosi",
    "genre": "Helen Chandler",
    "right1": "David Manners",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "The ancient vampire Count Dracula arrives in England and begins to prey upon the virtuous young Mina.",
    "imdblink": "Dracula_thumb.jpg",
    "websiteurl": "1h 25min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0021814/?ref_=nv_sr_3"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "USM",
    "propname": "Forbidden Planet",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "CAN",
    "propname": "Forbidden Planet",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "UK",
    "propname": "Forbidden Planet",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "FRMET",
    "propname": "Forbidden Planet",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "GER",
    "propname": "Forbidden Planet",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "ITALY",
    "propname": "Forbidden Planet",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "SPN",
    "propname": "Forbidden Planet",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "BENELUX",
    "propname": "Forbidden Planet",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "SCAN",
    "propname": "Forbidden Planet",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "ICE",
    "propname": "Forbidden Planet",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "SWISS",
    "propname": "Forbidden Planet",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "GRC",
    "propname": "Forbidden Planet",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "POR",
    "propname": "Forbidden Planet",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "AUST",
    "propname": "Forbidden Planet",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Sold",
    "right5": "Sold",
    "right6": "Sold",
    "right7": "Sold",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "NZ",
    "propname": "Forbidden Planet",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Sold",
    "right5": "Sold",
    "right6": "Sold",
    "right7": "Sold",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "JAP",
    "propname": "Forbidden Planet",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "SOKO",
    "propname": "Forbidden Planet",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "CAM",
    "propname": "Forbidden Planet",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "CHINA",
    "propname": "Forbidden Planet",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "HKG",
    "propname": "Forbidden Planet",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "MONGOLIA",
    "propname": "Forbidden Planet",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "INDNSA",
    "propname": "Forbidden Planet",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "MAL",
    "propname": "Forbidden Planet",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "PHIL",
    "propname": "Forbidden Planet",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "SING",
    "propname": "Forbidden Planet",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "TWN",
    "propname": "Forbidden Planet",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "THAI",
    "propname": "Forbidden Planet",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "VIET",
    "propname": "Forbidden Planet",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "IND",
    "propname": "Forbidden Planet",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "BUR",
    "propname": "Forbidden Planet",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "PAK",
    "propname": "Forbidden Planet",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "SRI",
    "propname": "Forbidden Planet",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "BRZ",
    "propname": "Forbidden Planet",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "MEX",
    "propname": "Forbidden Planet",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "WIND",
    "propname": "Forbidden Planet",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "LA",
    "propname": "Forbidden Planet",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "CIS",
    "propname": "Forbidden Planet",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "UKR",
    "propname": "Forbidden Planet",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "BAL",
    "propname": "Forbidden Planet",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "POL",
    "propname": "Forbidden Planet",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "CZECH",
    "propname": "Forbidden Planet",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "SLOV",
    "propname": "Forbidden Planet",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "HUNG",
    "propname": "Forbidden Planet",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "ALB",
    "propname": "Forbidden Planet",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "CROAT",
    "propname": "Forbidden Planet",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "SERB",
    "propname": "Forbidden Planet",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "SLV",
    "propname": "Forbidden Planet",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "ROM",
    "propname": "Forbidden Planet",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "BUL",
    "propname": "Forbidden Planet",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "ISR",
    "propname": "Forbidden Planet",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "MEMIP",
    "propname": "Forbidden Planet",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "TUR",
    "propname": "Forbidden Planet",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDENPL",
    "territorycd": "REPSOAFR",
    "propname": "Forbidden Planet",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Fred M. Wilcox",
    "directors_len": 27,
    "directors": "Director(s): Fred M. Wilcox",
    "producers_string": "Producer(s): Glenn Barber",
    "producers_len": 25,
    "producers": "Producer(s): Glenn Barber",
    "directors_producers": "Director(s): Fred M. Wilcox\\x0aProducer(s): Glenn Barber",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A starship crew goes to investigate the silence of a planets colony only to find two survivors and a deadly secret that one of them has.",
    "imdblink": "ForbiddenPlanet_thumb.jpg",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049223/?ref_=nv_sr_1"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "USM",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "CAN",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "UK",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "FRMET",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "GER",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "ITALY",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "SPN",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "BENELUX",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "SCAN",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "ICE",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "SWISS",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "GRC",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "POR",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "AUST",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "NZ",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "JAP",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "SOKO",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "CAM",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "CHINA",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "HKG",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "MONGOLIA",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "INDNSA",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "MAL",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "PHIL",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "SING",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "TWN",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "THAI",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "VIET",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "IND",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "BUR",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "PAK",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "SRI",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "BRZ",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "MEX",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "WIND",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "LA",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "CIS",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "UKR",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "BAL",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "POL",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "CZECH",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "SLOV",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "HUNG",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "ALB",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "CROAT",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "SERB",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "SLV",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "ROM",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "BUL",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "ISR",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "MEMIP",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "TUR",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FORBIDDEN1956",
    "territorycd": "REPSOAFR",
    "propname": "Forbidden Planet - 1956",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1955-1956",
    "directors_string": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "directors_len": 51,
    "directors": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Fred M. Wilcox (as Fred McLeod Wilcox)\\x0a",
    "actors": "Walter Pidgeon",
    "genre": "Anne Francis",
    "right1": "Leslie Nielsen",
    "right2": "Science Fiction",
    "right3": "Action",
    "right4": "Action-Adventure",
    "right5": "Avail",
    "right6": "Avail",
    "right7": "Avail",
    "right8": "Avail",
    "right9": "Avail",
    "right10": "Avail",
    "synopsis": "Avail",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "When Adams and his crew are sent to investigate the silence from a planet inhabited by scientists",
    "websiteurl": "he finds all but two have died. Dr. Morbius and his daughter Altaira have somehow survived a hideous monster which roams the planet. Unknown to Adams",
    "releaseyear": "Morbius has made a discovery",
    "notes": "and has no intention of sharing it (or his daughter!) with anyone.Written by Rob Hartill"
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "USM",
    "propname": "Frankenstein",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "CAN",
    "propname": "Frankenstein",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "UK",
    "propname": "Frankenstein",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "FRMET",
    "propname": "Frankenstein",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "GER",
    "propname": "Frankenstein",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "ITALY",
    "propname": "Frankenstein",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "SPN",
    "propname": "Frankenstein",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "BENELUX",
    "propname": "Frankenstein",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "SCAN",
    "propname": "Frankenstein",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "ICE",
    "propname": "Frankenstein",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "SWISS",
    "propname": "Frankenstein",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "GRC",
    "propname": "Frankenstein",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "POR",
    "propname": "Frankenstein",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "AUST",
    "propname": "Frankenstein",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "NZ",
    "propname": "Frankenstein",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "JAP",
    "propname": "Frankenstein",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "SOKO",
    "propname": "Frankenstein",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "CAM",
    "propname": "Frankenstein",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "CHINA",
    "propname": "Frankenstein",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "HKG",
    "propname": "Frankenstein",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "MONGOLIA",
    "propname": "Frankenstein",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "INDNSA",
    "propname": "Frankenstein",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "MAL",
    "propname": "Frankenstein",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "PHIL",
    "propname": "Frankenstein",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "SING",
    "propname": "Frankenstein",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "TWN",
    "propname": "Frankenstein",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "THAI",
    "propname": "Frankenstein",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "VIET",
    "propname": "Frankenstein",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "IND",
    "propname": "Frankenstein",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "BUR",
    "propname": "Frankenstein",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "PAK",
    "propname": "Frankenstein",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "SRI",
    "propname": "Frankenstein",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "BRZ",
    "propname": "Frankenstein",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "MEX",
    "propname": "Frankenstein",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "WIND",
    "propname": "Frankenstein",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "LA",
    "propname": "Frankenstein",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "CIS",
    "propname": "Frankenstein",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "UKR",
    "propname": "Frankenstein",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "BAL",
    "propname": "Frankenstein",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "POL",
    "propname": "Frankenstein",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "CZECH",
    "propname": "Frankenstein",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "SLOV",
    "propname": "Frankenstein",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "HUNG",
    "propname": "Frankenstein",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "ALB",
    "propname": "Frankenstein",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "CROAT",
    "propname": "Frankenstein",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "SERB",
    "propname": "Frankenstein",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "SLV",
    "propname": "Frankenstein",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "ROM",
    "propname": "Frankenstein",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "BUL",
    "propname": "Frankenstein",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "ISR",
    "propname": "Frankenstein",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "MEMIP",
    "propname": "Frankenstein",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "TUR",
    "propname": "Frankenstein",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "FRANKENSTEIN",
    "territorycd": "REPSOAFR",
    "propname": "Frankenstein",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "1931",
    "directors_string": "Director(s): James Whale",
    "directors_len": 24,
    "directors": "Director(s): James Whale",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): James Whale\\x0a",
    "actors": "Colin Clive",
    "genre": "Mae Clarke",
    "right1": "Boris Karloff",
    "right2": "Drama",
    "right3": "Horror",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "An obsessed scientist assembles a living being from parts of exhumed corpses.",
    "websiteurl": "Frankenstein_thumb.jpg",
    "releaseyear": "1h 10min",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "USM",
    "propname": "Invaders from Mars",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "CAN",
    "propname": "Invaders from Mars",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "UK",
    "propname": "Invaders from Mars",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "FRMET",
    "propname": "Invaders from Mars",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "GER",
    "propname": "Invaders from Mars",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "ITALY",
    "propname": "Invaders from Mars",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "SPN",
    "propname": "Invaders from Mars",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "BENELUX",
    "propname": "Invaders from Mars",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "SCAN",
    "propname": "Invaders from Mars",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "ICE",
    "propname": "Invaders from Mars",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "SWISS",
    "propname": "Invaders from Mars",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "GRC",
    "propname": "Invaders from Mars",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "POR",
    "propname": "Invaders from Mars",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "AUST",
    "propname": "Invaders from Mars",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "NZ",
    "propname": "Invaders from Mars",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "JAP",
    "propname": "Invaders from Mars",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "SOKO",
    "propname": "Invaders from Mars",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "CAM",
    "propname": "Invaders from Mars",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "CHINA",
    "propname": "Invaders from Mars",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "HKG",
    "propname": "Invaders from Mars",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "MONGOLIA",
    "propname": "Invaders from Mars",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "INDNSA",
    "propname": "Invaders from Mars",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "MAL",
    "propname": "Invaders from Mars",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "PHIL",
    "propname": "Invaders from Mars",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "SING",
    "propname": "Invaders from Mars",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "TWN",
    "propname": "Invaders from Mars",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "THAI",
    "propname": "Invaders from Mars",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "VIET",
    "propname": "Invaders from Mars",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "IND",
    "propname": "Invaders from Mars",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "BUR",
    "propname": "Invaders from Mars",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "PAK",
    "propname": "Invaders from Mars",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "SRI",
    "propname": "Invaders from Mars",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "MEX",
    "propname": "Invaders from Mars",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "BRZ",
    "propname": "Invaders from Mars",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "WIND",
    "propname": "Invaders from Mars",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "LA",
    "propname": "Invaders from Mars",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "CIS",
    "propname": "Invaders from Mars",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "UKR",
    "propname": "Invaders from Mars",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "BAL",
    "propname": "Invaders from Mars",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "POL",
    "propname": "Invaders from Mars",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "CZECH",
    "propname": "Invaders from Mars",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "SLOV",
    "propname": "Invaders from Mars",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "HUNG",
    "propname": "Invaders from Mars",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "ALB",
    "propname": "Invaders from Mars",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "CROAT",
    "propname": "Invaders from Mars",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "SERB",
    "propname": "Invaders from Mars",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "SLV",
    "propname": "Invaders from Mars",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "ROM",
    "propname": "Invaders from Mars",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "BUL",
    "propname": "Invaders from Mars",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "ISR",
    "propname": "Invaders from Mars",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "MEMIP",
    "propname": "Invaders from Mars",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "TUR",
    "propname": "Invaders from Mars",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVADERSMARS",
    "territorycd": "REPSOAFR",
    "propname": "Invaders from Mars",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): William Cameron Menzies",
    "directors_len": 36,
    "directors": "Director(s): William Cameron Menzies",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): William Cameron Menzies\\x0a",
    "actors": "Helena Carter",
    "genre": "Arthur Franz",
    "right1": "Jimmy Hunt",
    "right2": "Science Fiction",
    "right3": "New Release",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "A young boy learns that space aliens are taking over the minds of earthlings.",
    "ratingdesc": "InvadersfromMars_thumb.jpg",
    "imdblink": "1h 18min",
    "websiteurl": "",
    "releaseyear": "http://www.imdb.com/title/tt0045917/",
    "notes": ""
  },
  {
    "propno": "INVASION",
    "territorycd": "USM",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "CAN",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "UK",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "FRMET",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "GER",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "ITALY",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "SPN",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "BENELUX",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "SCAN",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "ICE",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "SWISS",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "GRC",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "POR",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "AUST",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "NZ",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "JAP",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "SOKO",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "CAM",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "CHINA",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "HKG",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "MONGOLIA",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "INDNSA",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "MAL",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "PHIL",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "SING",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "TWN",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "THAI",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "VIET",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "IND",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "BUR",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "PAK",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "SRI",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "MEX",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "BRZ",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "WIND",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "LA",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "CIS",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "UKR",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "BAL",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "POL",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "CZECH",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "SLOV",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "HUNG",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "ALB",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "CROAT",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "SERB",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "SLV",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "ROM",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "BUL",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "ISR",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "MEMIP",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "TUR",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "INVASION",
    "territorycd": "REPSOAFR",
    "propname": "Invasion of the Body Snatchers",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Don Siegel",
    "directors_len": 23,
    "directors": "Director(s): Don Siegel",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Don Siegel\\x0a",
    "actors": "Kevin McCarthy",
    "genre": "Dana Wynter",
    "right1": "Larry Gates",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A small-town doctor learns that the population of his community is being replaced by emotionless alien duplicates.",
    "imdblink": "InvasionoftheBodySnatchers_thumb.jpg",
    "websiteurl": "90",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0049366/"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "USM",
    "propname": "It Came from Outer Space",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "CAN",
    "propname": "It Came from Outer Space",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "UK",
    "propname": "It Came from Outer Space",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "FRMET",
    "propname": "It Came from Outer Space",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "GER",
    "propname": "It Came from Outer Space",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "ITALY",
    "propname": "It Came from Outer Space",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "SPN",
    "propname": "It Came from Outer Space",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "BENELUX",
    "propname": "It Came from Outer Space",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "SCAN",
    "propname": "It Came from Outer Space",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "ICE",
    "propname": "It Came from Outer Space",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "SWISS",
    "propname": "It Came from Outer Space",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "GRC",
    "propname": "It Came from Outer Space",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "POR",
    "propname": "It Came from Outer Space",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "AUST",
    "propname": "It Came from Outer Space",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "NZ",
    "propname": "It Came from Outer Space",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "JAP",
    "propname": "It Came from Outer Space",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "SOKO",
    "propname": "It Came from Outer Space",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "CAM",
    "propname": "It Came from Outer Space",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "CHINA",
    "propname": "It Came from Outer Space",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "HKG",
    "propname": "It Came from Outer Space",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "MONGOLIA",
    "propname": "It Came from Outer Space",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "INDNSA",
    "propname": "It Came from Outer Space",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "MAL",
    "propname": "It Came from Outer Space",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "PHIL",
    "propname": "It Came from Outer Space",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "SING",
    "propname": "It Came from Outer Space",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "TWN",
    "propname": "It Came from Outer Space",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "THAI",
    "propname": "It Came from Outer Space",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "VIET",
    "propname": "It Came from Outer Space",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "IND",
    "propname": "It Came from Outer Space",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "BUR",
    "propname": "It Came from Outer Space",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "PAK",
    "propname": "It Came from Outer Space",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "SRI",
    "propname": "It Came from Outer Space",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "BRZ",
    "propname": "It Came from Outer Space",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "MEX",
    "propname": "It Came from Outer Space",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "WIND",
    "propname": "It Came from Outer Space",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "LA",
    "propname": "It Came from Outer Space",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "CIS",
    "propname": "It Came from Outer Space",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "UKR",
    "propname": "It Came from Outer Space",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "BAL",
    "propname": "It Came from Outer Space",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "POL",
    "propname": "It Came from Outer Space",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "CZECH",
    "propname": "It Came from Outer Space",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "SLOV",
    "propname": "It Came from Outer Space",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "HUNG",
    "propname": "It Came from Outer Space",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "ALB",
    "propname": "It Came from Outer Space",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "CROAT",
    "propname": "It Came from Outer Space",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "SERB",
    "propname": "It Came from Outer Space",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "SLV",
    "propname": "It Came from Outer Space",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "ROM",
    "propname": "It Came from Outer Space",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "BUL",
    "propname": "It Came from Outer Space",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "ISR",
    "propname": "It Came from Outer Space",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "MEMIP",
    "propname": "It Came from Outer Space",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "TUR",
    "propname": "It Came from Outer Space",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "ITCAMEFROMOS",
    "territorycd": "REPSOAFR",
    "propname": "It Came from Outer Space",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "September 2013",
    "directors_string": "Director(s): Jack Arnold",
    "directors_len": 24,
    "directors": "Director(s): Jack Arnold",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Jack Arnold\\x0a",
    "actors": "Richard Carlson",
    "genre": "Barbara Rush",
    "right1": "Charles Drake",
    "right2": "Action-Adventure",
    "right3": "Science Fiction",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A spaceship from another world crashes in the Arizona desert",
    "websiteurl": "and only an amateur stargazer and a schoolteacher suspect alien influence when the local townsfolk begin to act strange.",
    "releaseyear": "ItCameFromOuterSpace_thumb.jpg",
    "notes": "80min"
  },
  {
    "propno": "KING KONG",
    "territorycd": "USM",
    "propname": "King Kong",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "CAN",
    "propname": "King Kong",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "UK",
    "propname": "King Kong",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "FRMET",
    "propname": "King Kong",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "GER",
    "propname": "King Kong",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "ITALY",
    "propname": "King Kong",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "SPN",
    "propname": "King Kong",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "BENELUX",
    "propname": "King Kong",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "SCAN",
    "propname": "King Kong",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "ICE",
    "propname": "King Kong",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "SWISS",
    "propname": "King Kong",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "GRC",
    "propname": "King Kong",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "POR",
    "propname": "King Kong",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "AUST",
    "propname": "King Kong",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "NZ",
    "propname": "King Kong",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "JAP",
    "propname": "King Kong",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "SOKO",
    "propname": "King Kong",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "CAM",
    "propname": "King Kong",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "CHINA",
    "propname": "King Kong",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "HKG",
    "propname": "King Kong",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "MONGOLIA",
    "propname": "King Kong",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "INDNSA",
    "propname": "King Kong",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "MAL",
    "propname": "King Kong",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "PHIL",
    "propname": "King Kong",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "SING",
    "propname": "King Kong",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "TWN",
    "propname": "King Kong",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "THAI",
    "propname": "King Kong",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "VIET",
    "propname": "King Kong",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "IND",
    "propname": "King Kong",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "BUR",
    "propname": "King Kong",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "PAK",
    "propname": "King Kong",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "SRI",
    "propname": "King Kong",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "BRZ",
    "propname": "King Kong",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "MEX",
    "propname": "King Kong",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "WIND",
    "propname": "King Kong",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "LA",
    "propname": "King Kong",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "CIS",
    "propname": "King Kong",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "UKR",
    "propname": "King Kong",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "BAL",
    "propname": "King Kong",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "POL",
    "propname": "King Kong",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "CZECH",
    "propname": "King Kong",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "SLOV",
    "propname": "King Kong",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "HUNG",
    "propname": "King Kong",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "ALB",
    "propname": "King Kong",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "CROAT",
    "propname": "King Kong",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "SERB",
    "propname": "King Kong",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "SLV",
    "propname": "King Kong",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "ROM",
    "propname": "King Kong",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "BUL",
    "propname": "King Kong",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "ISR",
    "propname": "King Kong",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "MEMIP",
    "propname": "King Kong",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "TUR",
    "propname": "King Kong",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "KING KONG",
    "territorycd": "REPSOAFR",
    "propname": "King Kong",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Merian C. Cooper",
    "directors_len": "Ernest B. Schoedsack",
    "directors": 51,
    "producers_string": "Director(s): Merian C. Cooper",
    "producers_len": "Ernest B. Schoedsack",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Merian C. Cooper",
    "right1": "Ernest B. Schoedsack\\x0a",
    "right2": "Fay Wray",
    "right3": "Robert Armstrong",
    "right4": "Bruce Cabot",
    "right5": "Action-Adventure",
    "right6": "Fantasy",
    "right7": "Horror",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "New Release",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "",
    "notes": "A film crew goes to a tropical island for an exotic location shoot and discovers a colossal giant gorilla who takes a shine to their female blonde star. He is then captured and brought back to New York City for public exhibition."
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "USM",
    "propname": "Nosferatu",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "CAN",
    "propname": "Nosferatu",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "UK",
    "propname": "Nosferatu",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "FRMET",
    "propname": "Nosferatu",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "GER",
    "propname": "Nosferatu",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "ITALY",
    "propname": "Nosferatu",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "SPN",
    "propname": "Nosferatu",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "BENELUX",
    "propname": "Nosferatu",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "SCAN",
    "propname": "Nosferatu",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "ICE",
    "propname": "Nosferatu",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "SWISS",
    "propname": "Nosferatu",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "GRC",
    "propname": "Nosferatu",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "POR",
    "propname": "Nosferatu",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "AUST",
    "propname": "Nosferatu",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "NZ",
    "propname": "Nosferatu",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "JAP",
    "propname": "Nosferatu",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "SOKO",
    "propname": "Nosferatu",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "CAM",
    "propname": "Nosferatu",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "CHINA",
    "propname": "Nosferatu",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "HKG",
    "propname": "Nosferatu",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "MONGOLIA",
    "propname": "Nosferatu",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "INDNSA",
    "propname": "Nosferatu",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "MAL",
    "propname": "Nosferatu",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "PHIL",
    "propname": "Nosferatu",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "SING",
    "propname": "Nosferatu",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "TWN",
    "propname": "Nosferatu",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "THAI",
    "propname": "Nosferatu",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "VIET",
    "propname": "Nosferatu",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "IND",
    "propname": "Nosferatu",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "BUR",
    "propname": "Nosferatu",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "PAK",
    "propname": "Nosferatu",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "SRI",
    "propname": "Nosferatu",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "BRZ",
    "propname": "Nosferatu",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "MEX",
    "propname": "Nosferatu",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "WIND",
    "propname": "Nosferatu",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "LA",
    "propname": "Nosferatu",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "CIS",
    "propname": "Nosferatu",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "UKR",
    "propname": "Nosferatu",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "BAL",
    "propname": "Nosferatu",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "POL",
    "propname": "Nosferatu",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "CZECH",
    "propname": "Nosferatu",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "SLOV",
    "propname": "Nosferatu",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "HUNG",
    "propname": "Nosferatu",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "ALB",
    "propname": "Nosferatu",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "CROAT",
    "propname": "Nosferatu",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "SERB",
    "propname": "Nosferatu",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "SLV",
    "propname": "Nosferatu",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "ROM",
    "propname": "Nosferatu",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "BUL",
    "propname": "Nosferatu",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "ISR",
    "propname": "Nosferatu",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "MEMIP",
    "propname": "Nosferatu",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "TUR",
    "propname": "Nosferatu",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "NOSFERATU",
    "territorycd": "REPSOAFR",
    "propname": "Nosferatu",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): F.W. Murnau",
    "directors_len": 24,
    "directors": "Director(s): F.W. Murnau",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): F.W. Murnau\\x0a",
    "actors": "Max Schreck",
    "genre": "Greta Schroder",
    "right1": "Ruth Landshoff",
    "right2": "Fantasy",
    "right3": "Horror",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Vampire Count Orlok expresses interest in a new residence and real estate agent Hutters wife.",
    "imdblink": "Nosferatu_thumb.jpg",
    "websiteurl": "81min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0013442/?ref_=fn_al_tt_1"
  },
  {
    "propno": "PLAN9",
    "territorycd": "USM",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "CAN",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "UK",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "FRMET",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "GER",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "ITALY",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "SPN",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "BENELUX",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "SCAN",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "ICE",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "SWISS",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "GRC",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "POR",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "AUST",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "NZ",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "JAP",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "SOKO",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "CAM",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "CHINA",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "HKG",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "MONGOLIA",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "INDNSA",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "MAL",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "PHIL",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "SING",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "TWN",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "THAI",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "VIET",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "IND",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "BUR",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "PAK",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "SRI",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "MEX",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "BRZ",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "WIND",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "LA",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "CIS",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "UKR",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "BAL",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "POL",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "CZECH",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "SLOV",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "HUNG",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "ALB",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "CROAT",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "SERB",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "SLV",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "ROM",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "BUL",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "ISR",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "MEMIP",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "TUR",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "PLAN9",
    "territorycd": "REPSOAFR",
    "propname": "Plan 9 from Outer Space",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): ",
    "directors_len": 13,
    "directors": "",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "\\x0a",
    "actors": "Gregory Walcott",
    "genre": "Tom Keene",
    "right1": "Mona McKinnon",
    "right2": "Horror",
    "right3": "Science Fiction",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "Aliens resurrect dead humans as zombies and vampires to stop humanity from creating the Solaranite (a sort of sun-driven bomb).",
    "imdblink": "Plan9FromOuterSpace_thumb.jpg",
    "websiteurl": "79min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0052077/"
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "USM",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "United States",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "CAN",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Canada",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "UK",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "United Kingdom",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "FRMET",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "France",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "GER",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Germany",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "ITALY",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Italy",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "SPN",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Spain",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "BENELUX",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Benelux",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "SCAN",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Scandinavia",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "ICE",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Iceland",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "SWISS",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Switzerland",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "GRC",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Greece",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "POR",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Portugal",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "AUST",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Australia",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "NZ",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "New Zealand",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "JAP",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Japan",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "SOKO",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "South Korea",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "CAM",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Cambodia",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "CHINA",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "China",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "HKG",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Hong Kong",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "MONGOLIA",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Mongolia",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "INDNSA",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Indonesia",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "MAL",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Malaysia",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "PHIL",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Philippines",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "SING",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Singapore",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "TWN",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Taiwan",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "THAI",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Thailand",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "VIET",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Vietnam",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "IND",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "India",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "BUR",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Myanmar (Burma)",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "PAK",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Pakistan",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "SRI",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Sri Lanka",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "BRZ",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Brazil",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "MEX",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Mexico",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "WIND",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "West Indies",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "LA",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Latin America",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "CIS",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "CIS",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "UKR",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Ukraine",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "BAL",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Baltic States",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "POL",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Poland",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "CZECH",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Czech Republic",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "SLOV",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Slovak Republic",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "HUNG",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Hungary",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "ALB",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Albania",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "CROAT",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Croatia",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "SERB",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Serbia",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "SLV",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Slovenia",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "ROM",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Romania",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "BUL",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Bulgaria",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "ISR",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Israel",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "MEMIP",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Middle East",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "TUR",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "Turkey",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "BEASTFROM50K",
    "territorycd": "REPSOAFR",
    "propname": "The Beast From 20",
    "territoryname": "000 Fathoms",
    "library": "South Africa",
    "hd": "Fake Films",
    "production_dates": "No",
    "directors_string": "2013",
    "directors_len": "Director(s): Eugene Lourie",
    "directors": 26,
    "producers_string": "Director(s): Eugene Lourie",
    "producers_len": "Producer(s): ",
    "producers": 13,
    "directors_producers": "",
    "actors": "Director(s): Eugene Lourie\\x0a",
    "genre": "Paul Hubschmid",
    "right1": "Paula Raymond",
    "right2": "Cecil Kellaway",
    "right3": "Adventure",
    "right4": "Horror",
    "right5": "Science Fiction",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "A ferocious dinosaur awakened by an Arctic atomic test terrorizes the North Atlantic and",
    "releaseyear": "ultimately",
    "notes": "New York City."
  },
  {
    "propno": "THEBLOB",
    "territorycd": "USM",
    "propname": "The Blob",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "CAN",
    "propname": "The Blob",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "UK",
    "propname": "The Blob",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "FRMET",
    "propname": "The Blob",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "GER",
    "propname": "The Blob",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "ITALY",
    "propname": "The Blob",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "SPN",
    "propname": "The Blob",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "BENELUX",
    "propname": "The Blob",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "SCAN",
    "propname": "The Blob",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "ICE",
    "propname": "The Blob",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "SWISS",
    "propname": "The Blob",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "GRC",
    "propname": "The Blob",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "POR",
    "propname": "The Blob",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "AUST",
    "propname": "The Blob",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "NZ",
    "propname": "The Blob",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "JAP",
    "propname": "The Blob",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "SOKO",
    "propname": "The Blob",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "CAM",
    "propname": "The Blob",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "CHINA",
    "propname": "The Blob",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "HKG",
    "propname": "The Blob",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "MONGOLIA",
    "propname": "The Blob",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "INDNSA",
    "propname": "The Blob",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "MAL",
    "propname": "The Blob",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "PHIL",
    "propname": "The Blob",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "SING",
    "propname": "The Blob",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "TWN",
    "propname": "The Blob",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "THAI",
    "propname": "The Blob",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "VIET",
    "propname": "The Blob",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "IND",
    "propname": "The Blob",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "BUR",
    "propname": "The Blob",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "PAK",
    "propname": "The Blob",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "SRI",
    "propname": "The Blob",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "MEX",
    "propname": "The Blob",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "BRZ",
    "propname": "The Blob",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "WIND",
    "propname": "The Blob",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "LA",
    "propname": "The Blob",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "CIS",
    "propname": "The Blob",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "UKR",
    "propname": "The Blob",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "BAL",
    "propname": "The Blob",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "POL",
    "propname": "The Blob",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "CZECH",
    "propname": "The Blob",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "SLOV",
    "propname": "The Blob",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "HUNG",
    "propname": "The Blob",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "ALB",
    "propname": "The Blob",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "CROAT",
    "propname": "The Blob",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "SERB",
    "propname": "The Blob",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "SLV",
    "propname": "The Blob",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "ROM",
    "propname": "The Blob",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "BUL",
    "propname": "The Blob",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "ISR",
    "propname": "The Blob",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "MEMIP",
    "propname": "The Blob",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "TUR",
    "propname": "The Blob",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEBLOB",
    "territorycd": "REPSOAFR",
    "propname": "The Blob",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Irvin S. Yeaworth Jr.",
    "directors_len": "Russel S. Doughten Jr.",
    "directors": 58,
    "producers_string": "Director(s): Irvin S. Yeaworth Jr.",
    "producers_len": "Russel S. Doughten Jr.",
    "producers": "Producer(s): ",
    "directors_producers": 13,
    "actors": "",
    "genre": "Director(s): Irvin S. Yeaworth Jr.",
    "right1": "Russel S. Doughten Jr.\\x0a",
    "right2": "Steve McQueen",
    "right3": "Aneta Corsaut",
    "right4": "Earl Rowe",
    "right5": "Horror",
    "right6": "Science Fiction",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "New Release",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "",
    "releaseyear": "An alien lifeform consumes everything in its path as it grows and grows.",
    "notes": "TheBlob_thumb.jpg"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "USM",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "CAN",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "UK",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "FRMET",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "GER",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "ITALY",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "SPN",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "BENELUX",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "SCAN",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "ICE",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "SWISS",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "GRC",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "POR",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "AUST",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "NZ",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "JAP",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "SOKO",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "CAM",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "CHINA",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "HKG",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "MONGOLIA",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "INDNSA",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "MAL",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "PHIL",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "SING",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "TWN",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "THAI",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "VIET",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "IND",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "BUR",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "PAK",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "SRI",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "MEX",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "BRZ",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "WIND",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "LA",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "CIS",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "UKR",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "BAL",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "POL",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "CZECH",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "SLOV",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "HUNG",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "ALB",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "CROAT",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "SERB",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "SLV",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "ROM",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "BUL",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "ISR",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "MEMIP",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "TUR",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEDAYTHEEARTH",
    "territorycd": "REPSOAFR",
    "propname": "The Day the Earth Stood Still",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "9/9/2013",
    "directors_string": "Director(s): Robert Wise",
    "directors_len": 24,
    "directors": "Director(s): Robert Wise",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Robert Wise\\x0a",
    "actors": "Michael Rennie",
    "genre": "Patricia Neal",
    "right1": "Hugh Marlowe",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "An alien lands and tells the people of Earth that they must live peacefully or be destroyed as a danger to other planets.",
    "imdblink": "TheDaytheEarthStoodStill_thumb.jpg",
    "websiteurl": "100",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0043456/?ref_=nv_sr_2"
  },
  {
    "propno": "THEFLY",
    "territorycd": "USM",
    "propname": "The Fly",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "CAN",
    "propname": "The Fly",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "UK",
    "propname": "The Fly",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "FRMET",
    "propname": "The Fly",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "GER",
    "propname": "The Fly",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "ITALY",
    "propname": "The Fly",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "SPN",
    "propname": "The Fly",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "BENELUX",
    "propname": "The Fly",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "SCAN",
    "propname": "The Fly",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "ICE",
    "propname": "The Fly",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "SWISS",
    "propname": "The Fly",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "GRC",
    "propname": "The Fly",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "POR",
    "propname": "The Fly",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "AUST",
    "propname": "The Fly",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "NZ",
    "propname": "The Fly",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "JAP",
    "propname": "The Fly",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "SOKO",
    "propname": "The Fly",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "CAM",
    "propname": "The Fly",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "CHINA",
    "propname": "The Fly",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "HKG",
    "propname": "The Fly",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "MONGOLIA",
    "propname": "The Fly",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "INDNSA",
    "propname": "The Fly",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "MAL",
    "propname": "The Fly",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "PHIL",
    "propname": "The Fly",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "SING",
    "propname": "The Fly",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "TWN",
    "propname": "The Fly",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "THAI",
    "propname": "The Fly",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "VIET",
    "propname": "The Fly",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "IND",
    "propname": "The Fly",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "BUR",
    "propname": "The Fly",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "PAK",
    "propname": "The Fly",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "SRI",
    "propname": "The Fly",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "MEX",
    "propname": "The Fly",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "BRZ",
    "propname": "The Fly",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "WIND",
    "propname": "The Fly",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "LA",
    "propname": "The Fly",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "CIS",
    "propname": "The Fly",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "UKR",
    "propname": "The Fly",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "BAL",
    "propname": "The Fly",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "POL",
    "propname": "The Fly",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "CZECH",
    "propname": "The Fly",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "SLOV",
    "propname": "The Fly",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "HUNG",
    "propname": "The Fly",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "ALB",
    "propname": "The Fly",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "CROAT",
    "propname": "The Fly",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "SERB",
    "propname": "The Fly",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "SLV",
    "propname": "The Fly",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "ROM",
    "propname": "The Fly",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "BUL",
    "propname": "The Fly",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "ISR",
    "propname": "The Fly",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "MEMIP",
    "propname": "The Fly",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "TUR",
    "propname": "The Fly",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THEFLY",
    "territorycd": "REPSOAFR",
    "propname": "The Fly",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "02/12/2013",
    "directors_string": "Director(s): Kurt Neumann",
    "directors_len": 25,
    "directors": "Director(s): Kurt Neumann",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Kurt Neumann\\x0a",
    "actors": "David Hedison",
    "genre": "Patricia Owens",
    "right1": "Vincent Price",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Fantasy",
    "right5": "Horror",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "\\x0d\\x0aA scientist has a horrific accident when he tries to use his newly invented teleportation device.",
    "releaseyear": "TheFly_thumb.jpg",
    "notes": "90"
  },
  {
    "propno": "THETHING",
    "territorycd": "USM",
    "propname": "The Thing",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "CAN",
    "propname": "The Thing",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "UK",
    "propname": "The Thing",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "FRMET",
    "propname": "The Thing",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "GER",
    "propname": "The Thing",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "ITALY",
    "propname": "The Thing",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "SPN",
    "propname": "The Thing",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "BENELUX",
    "propname": "The Thing",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "SCAN",
    "propname": "The Thing",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "ICE",
    "propname": "The Thing",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "SWISS",
    "propname": "The Thing",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "GRC",
    "propname": "The Thing",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "POR",
    "propname": "The Thing",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "AUST",
    "propname": "The Thing",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "NZ",
    "propname": "The Thing",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "JAP",
    "propname": "The Thing",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "SOKO",
    "propname": "The Thing",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "CAM",
    "propname": "The Thing",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "CHINA",
    "propname": "The Thing",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "HKG",
    "propname": "The Thing",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "MONGOLIA",
    "propname": "The Thing",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "INDNSA",
    "propname": "The Thing",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "MAL",
    "propname": "The Thing",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "PHIL",
    "propname": "The Thing",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "SING",
    "propname": "The Thing",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "TWN",
    "propname": "The Thing",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "THAI",
    "propname": "The Thing",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "VIET",
    "propname": "The Thing",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "IND",
    "propname": "The Thing",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "BUR",
    "propname": "The Thing",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "PAK",
    "propname": "The Thing",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "SRI",
    "propname": "The Thing",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "MEX",
    "propname": "The Thing",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "BRZ",
    "propname": "The Thing",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "WIND",
    "propname": "The Thing",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "LA",
    "propname": "The Thing",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "CIS",
    "propname": "The Thing",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "UKR",
    "propname": "The Thing",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "BAL",
    "propname": "The Thing",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "POL",
    "propname": "The Thing",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "CZECH",
    "propname": "The Thing",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "SLOV",
    "propname": "The Thing",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "HUNG",
    "propname": "The Thing",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "ALB",
    "propname": "The Thing",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "CROAT",
    "propname": "The Thing",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "SERB",
    "propname": "The Thing",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "SLV",
    "propname": "The Thing",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "ROM",
    "propname": "The Thing",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "BUL",
    "propname": "The Thing",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "ISR",
    "propname": "The Thing",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "MEMIP",
    "propname": "The Thing",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "TUR",
    "propname": "The Thing",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "THETHING",
    "territorycd": "REPSOAFR",
    "propname": "The Thing",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): John Carpenter",
    "directors_len": 27,
    "directors": "Director(s): John Carpenter",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): John Carpenter\\x0a",
    "actors": "Kurt Russel",
    "genre": "Wilford Brimley",
    "right1": "Keith David",
    "right2": "Science Fiction",
    "right3": "Horror",
    "right4": "Mystery",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Its the first week of winter in 1982. An American Research Base is greeted by an alien force",
    "websiteurl": "that can assimilate anything it touches. Its up to the members to stay alive",
    "releaseyear": "and be sure of who is human",
    "notes": "and who has become one of the Things."
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "USM",
    "propname": "The Time Machine",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "CAN",
    "propname": "The Time Machine",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "UK",
    "propname": "The Time Machine",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "FRMET",
    "propname": "The Time Machine",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "GER",
    "propname": "The Time Machine",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "ITALY",
    "propname": "The Time Machine",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "SPN",
    "propname": "The Time Machine",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "BENELUX",
    "propname": "The Time Machine",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "SCAN",
    "propname": "The Time Machine",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "ICE",
    "propname": "The Time Machine",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "SWISS",
    "propname": "The Time Machine",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "GRC",
    "propname": "The Time Machine",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "POR",
    "propname": "The Time Machine",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "AUST",
    "propname": "The Time Machine",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "NZ",
    "propname": "The Time Machine",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "JAP",
    "propname": "The Time Machine",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "SOKO",
    "propname": "The Time Machine",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "CAM",
    "propname": "The Time Machine",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "CHINA",
    "propname": "The Time Machine",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "HKG",
    "propname": "The Time Machine",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "MONGOLIA",
    "propname": "The Time Machine",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "INDNSA",
    "propname": "The Time Machine",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "MAL",
    "propname": "The Time Machine",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "PHIL",
    "propname": "The Time Machine",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "SING",
    "propname": "The Time Machine",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "TWN",
    "propname": "The Time Machine",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "THAI",
    "propname": "The Time Machine",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "VIET",
    "propname": "The Time Machine",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "IND",
    "propname": "The Time Machine",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "BUR",
    "propname": "The Time Machine",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "PAK",
    "propname": "The Time Machine",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "SRI",
    "propname": "The Time Machine",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "MEX",
    "propname": "The Time Machine",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "BRZ",
    "propname": "The Time Machine",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "WIND",
    "propname": "The Time Machine",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "LA",
    "propname": "The Time Machine",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "CIS",
    "propname": "The Time Machine",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "UKR",
    "propname": "The Time Machine",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "BAL",
    "propname": "The Time Machine",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "POL",
    "propname": "The Time Machine",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "CZECH",
    "propname": "The Time Machine",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "SLOV",
    "propname": "The Time Machine",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "HUNG",
    "propname": "The Time Machine",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "ALB",
    "propname": "The Time Machine",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "CROAT",
    "propname": "The Time Machine",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "SERB",
    "propname": "The Time Machine",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "SLV",
    "propname": "The Time Machine",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "ROM",
    "propname": "The Time Machine",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "BUL",
    "propname": "The Time Machine",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "ISR",
    "propname": "The Time Machine",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "MEMIP",
    "propname": "The Time Machine",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "TUR",
    "propname": "The Time Machine",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "TIMEMACHINE",
    "territorycd": "REPSOAFR",
    "propname": "The Time Machine",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): George Pal",
    "directors_len": 23,
    "directors": "Director(s): George Pal",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): George Pal\\x0a",
    "actors": "Rod Taylor",
    "genre": "Alan Young",
    "right1": "Yvette Mimieux",
    "right2": "Science Fiction",
    "right3": "Adventure",
    "right4": "New Release",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "A mans vision for a utopian society is disillusioned when travelling forward into time reveals a dark and dangerous society.",
    "imdblink": "TheTimeMachine_thumb.jpg",
    "websiteurl": "103min",
    "releaseyear": "",
    "notes": "http://www.imdb.com/title/tt0054387/?ref_=nv_sr_2"
  },
  {
    "propno": "THEM",
    "territorycd": "USM",
    "propname": "Them! ",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "CAN",
    "propname": "Them! ",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "UK",
    "propname": "Them! ",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "FRMET",
    "propname": "Them! ",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "GER",
    "propname": "Them! ",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "ITALY",
    "propname": "Them! ",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "SPN",
    "propname": "Them! ",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "BENELUX",
    "propname": "Them! ",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "SCAN",
    "propname": "Them! ",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "ICE",
    "propname": "Them! ",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "SWISS",
    "propname": "Them! ",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "GRC",
    "propname": "Them! ",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "POR",
    "propname": "Them! ",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "AUST",
    "propname": "Them! ",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "NZ",
    "propname": "Them! ",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "JAP",
    "propname": "Them! ",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "SOKO",
    "propname": "Them! ",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "CAM",
    "propname": "Them! ",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "CHINA",
    "propname": "Them! ",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "HKG",
    "propname": "Them! ",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "MONGOLIA",
    "propname": "Them! ",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "INDNSA",
    "propname": "Them! ",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "MAL",
    "propname": "Them! ",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "PHIL",
    "propname": "Them! ",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "SING",
    "propname": "Them! ",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "TWN",
    "propname": "Them! ",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "THAI",
    "propname": "Them! ",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "VIET",
    "propname": "Them! ",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "IND",
    "propname": "Them! ",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "BUR",
    "propname": "Them! ",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "PAK",
    "propname": "Them! ",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "SRI",
    "propname": "Them! ",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "MEX",
    "propname": "Them! ",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "BRZ",
    "propname": "Them! ",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "WIND",
    "propname": "Them! ",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "LA",
    "propname": "Them! ",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "CIS",
    "propname": "Them! ",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "UKR",
    "propname": "Them! ",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "BAL",
    "propname": "Them! ",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "POL",
    "propname": "Them! ",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "CZECH",
    "propname": "Them! ",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "SLOV",
    "propname": "Them! ",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "HUNG",
    "propname": "Them! ",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "ALB",
    "propname": "Them! ",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "CROAT",
    "propname": "Them! ",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "SERB",
    "propname": "Them! ",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "SLV",
    "propname": "Them! ",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "ROM",
    "propname": "Them! ",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "BUL",
    "propname": "Them! ",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "ISR",
    "propname": "Them! ",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "MEMIP",
    "propname": "Them! ",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "TUR",
    "propname": "Them! ",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "THEM",
    "territorycd": "REPSOAFR",
    "propname": "Them! ",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Gordon Douglas",
    "directors_len": 27,
    "directors": "Director(s): Gordon Douglas",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Gordon Douglas\\x0a",
    "actors": "James Whitmore",
    "genre": "Edmund Gwenn",
    "right1": "Joan Weldon",
    "right2": "Science Fiction",
    "right3": "Drama",
    "right4": "Horror",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "The earliest atomic tests in New Mexico cause common ants to mutate into giant man-eating monsters that threaten civilization.",
    "websiteurl": "Them_thumb.jpg",
    "releaseyear": "94min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "USM",
    "propname": "This Island Earth",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "CAN",
    "propname": "This Island Earth",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "UK",
    "propname": "This Island Earth",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "FRMET",
    "propname": "This Island Earth",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "GER",
    "propname": "This Island Earth",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "ITALY",
    "propname": "This Island Earth",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "SPN",
    "propname": "This Island Earth",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "BENELUX",
    "propname": "This Island Earth",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "SCAN",
    "propname": "This Island Earth",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "ICE",
    "propname": "This Island Earth",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "SWISS",
    "propname": "This Island Earth",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "GRC",
    "propname": "This Island Earth",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "POR",
    "propname": "This Island Earth",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "AUST",
    "propname": "This Island Earth",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "NZ",
    "propname": "This Island Earth",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "JAP",
    "propname": "This Island Earth",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "SOKO",
    "propname": "This Island Earth",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "CAM",
    "propname": "This Island Earth",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "CHINA",
    "propname": "This Island Earth",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "HKG",
    "propname": "This Island Earth",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "MONGOLIA",
    "propname": "This Island Earth",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "INDNSA",
    "propname": "This Island Earth",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "MAL",
    "propname": "This Island Earth",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "PHIL",
    "propname": "This Island Earth",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "SING",
    "propname": "This Island Earth",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "TWN",
    "propname": "This Island Earth",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "THAI",
    "propname": "This Island Earth",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "VIET",
    "propname": "This Island Earth",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "IND",
    "propname": "This Island Earth",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "BUR",
    "propname": "This Island Earth",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "PAK",
    "propname": "This Island Earth",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "SRI",
    "propname": "This Island Earth",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "MEX",
    "propname": "This Island Earth",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "BRZ",
    "propname": "This Island Earth",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "WIND",
    "propname": "This Island Earth",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "LA",
    "propname": "This Island Earth",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "CIS",
    "propname": "This Island Earth",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "UKR",
    "propname": "This Island Earth",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "BAL",
    "propname": "This Island Earth",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "POL",
    "propname": "This Island Earth",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "CZECH",
    "propname": "This Island Earth",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "SLOV",
    "propname": "This Island Earth",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "HUNG",
    "propname": "This Island Earth",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "ALB",
    "propname": "This Island Earth",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "CROAT",
    "propname": "This Island Earth",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "SERB",
    "propname": "This Island Earth",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "SLV",
    "propname": "This Island Earth",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "ROM",
    "propname": "This Island Earth",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "BUL",
    "propname": "This Island Earth",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "ISR",
    "propname": "This Island Earth",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "MEMIP",
    "propname": "This Island Earth",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "TUR",
    "propname": "This Island Earth",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "ISLANDEARTH",
    "territorycd": "REPSOAFR",
    "propname": "This Island Earth",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Joseph M. Newman",
    "directors_len": 29,
    "directors": "Director(s): Joseph M. Newman",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Joseph M. Newman\\x0a",
    "actors": "Jeff Morrow",
    "genre": "Faith Domergue",
    "right1": "Rex Reason",
    "right2": "Horror",
    "right3": "Mystery",
    "right4": "Science Fiction",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "Aliens come to Earth seeking scientists to help them in their war.",
    "websiteurl": "ThisIslandEarth_thumb.jpg",
    "releaseyear": "87min",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "USM",
    "propname": "War of the Worlds ",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "CAN",
    "propname": "War of the Worlds ",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "UK",
    "propname": "War of the Worlds ",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "FRMET",
    "propname": "War of the Worlds ",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "GER",
    "propname": "War of the Worlds ",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "ITALY",
    "propname": "War of the Worlds ",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "SPN",
    "propname": "War of the Worlds ",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "BENELUX",
    "propname": "War of the Worlds ",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "SCAN",
    "propname": "War of the Worlds ",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "ICE",
    "propname": "War of the Worlds ",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "SWISS",
    "propname": "War of the Worlds ",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "GRC",
    "propname": "War of the Worlds ",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "POR",
    "propname": "War of the Worlds ",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "AUST",
    "propname": "War of the Worlds ",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "NZ",
    "propname": "War of the Worlds ",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "JAP",
    "propname": "War of the Worlds ",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "SOKO",
    "propname": "War of the Worlds ",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "CAM",
    "propname": "War of the Worlds ",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "CHINA",
    "propname": "War of the Worlds ",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "HKG",
    "propname": "War of the Worlds ",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "MONGOLIA",
    "propname": "War of the Worlds ",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "INDNSA",
    "propname": "War of the Worlds ",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "MAL",
    "propname": "War of the Worlds ",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "PHIL",
    "propname": "War of the Worlds ",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "SING",
    "propname": "War of the Worlds ",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "TWN",
    "propname": "War of the Worlds ",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "THAI",
    "propname": "War of the Worlds ",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "VIET",
    "propname": "War of the Worlds ",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "IND",
    "propname": "War of the Worlds ",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "BUR",
    "propname": "War of the Worlds ",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "PAK",
    "propname": "War of the Worlds ",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "SRI",
    "propname": "War of the Worlds ",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "BRZ",
    "propname": "War of the Worlds ",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "MEX",
    "propname": "War of the Worlds ",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "WIND",
    "propname": "War of the Worlds ",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "LA",
    "propname": "War of the Worlds ",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "CIS",
    "propname": "War of the Worlds ",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "UKR",
    "propname": "War of the Worlds ",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "BAL",
    "propname": "War of the Worlds ",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "POL",
    "propname": "War of the Worlds ",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "CZECH",
    "propname": "War of the Worlds ",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "SLOV",
    "propname": "War of the Worlds ",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "HUNG",
    "propname": "War of the Worlds ",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "ALB",
    "propname": "War of the Worlds ",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "CROAT",
    "propname": "War of the Worlds ",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "SERB",
    "propname": "War of the Worlds ",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "SLV",
    "propname": "War of the Worlds ",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "ROM",
    "propname": "War of the Worlds ",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "BUL",
    "propname": "War of the Worlds ",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "ISR",
    "propname": "War of the Worlds ",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "MEMIP",
    "propname": "War of the Worlds ",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "TUR",
    "propname": "War of the Worlds ",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WAROFTHEW",
    "territorycd": "REPSOAFR",
    "propname": "War of the Worlds ",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "May 2014",
    "directors_string": "Director(s): Byron Haskin",
    "directors_len": 25,
    "directors": "Director(s): Byron Haskin",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Byron Haskin\\x0a",
    "actors": "Gene Barry",
    "genre": "Ann Robinson",
    "right1": "Les Tremayne",
    "right2": "Science Fiction",
    "right3": "Action-Adventure",
    "right4": "Thriller",
    "right5": "New Release",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "A small town in California is attacked by Martians.\\x0d\\x0a\\x0d\\x0a",
    "websiteurl": "WaroftheWorlds_thumb.jpeg",
    "releaseyear": "117",
    "notes": ""
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "USM",
    "propname": "When Worlds Collide",
    "territoryname": "United States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "CAN",
    "propname": "When Worlds Collide",
    "territoryname": "Canada",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "UK",
    "propname": "When Worlds Collide",
    "territoryname": "United Kingdom",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "FRMET",
    "propname": "When Worlds Collide",
    "territoryname": "France",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "GER",
    "propname": "When Worlds Collide",
    "territoryname": "Germany",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "ITALY",
    "propname": "When Worlds Collide",
    "territoryname": "Italy",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "SPN",
    "propname": "When Worlds Collide",
    "territoryname": "Spain",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "BENELUX",
    "propname": "When Worlds Collide",
    "territoryname": "Benelux",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "SCAN",
    "propname": "When Worlds Collide",
    "territoryname": "Scandinavia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "ICE",
    "propname": "When Worlds Collide",
    "territoryname": "Iceland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "SWISS",
    "propname": "When Worlds Collide",
    "territoryname": "Switzerland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "GRC",
    "propname": "When Worlds Collide",
    "territoryname": "Greece",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "POR",
    "propname": "When Worlds Collide",
    "territoryname": "Portugal",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "AUST",
    "propname": "When Worlds Collide",
    "territoryname": "Australia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "NZ",
    "propname": "When Worlds Collide",
    "territoryname": "New Zealand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "JAP",
    "propname": "When Worlds Collide",
    "territoryname": "Japan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "SOKO",
    "propname": "When Worlds Collide",
    "territoryname": "South Korea",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "CAM",
    "propname": "When Worlds Collide",
    "territoryname": "Cambodia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "CHINA",
    "propname": "When Worlds Collide",
    "territoryname": "China",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "HKG",
    "propname": "When Worlds Collide",
    "territoryname": "Hong Kong",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "MONGOLIA",
    "propname": "When Worlds Collide",
    "territoryname": "Mongolia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "INDNSA",
    "propname": "When Worlds Collide",
    "territoryname": "Indonesia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "MAL",
    "propname": "When Worlds Collide",
    "territoryname": "Malaysia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "PHIL",
    "propname": "When Worlds Collide",
    "territoryname": "Philippines",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "SING",
    "propname": "When Worlds Collide",
    "territoryname": "Singapore",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "TWN",
    "propname": "When Worlds Collide",
    "territoryname": "Taiwan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "THAI",
    "propname": "When Worlds Collide",
    "territoryname": "Thailand",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "VIET",
    "propname": "When Worlds Collide",
    "territoryname": "Vietnam",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "IND",
    "propname": "When Worlds Collide",
    "territoryname": "India",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "BUR",
    "propname": "When Worlds Collide",
    "territoryname": "Myanmar (Burma)",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "PAK",
    "propname": "When Worlds Collide",
    "territoryname": "Pakistan",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "SRI",
    "propname": "When Worlds Collide",
    "territoryname": "Sri Lanka",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "MEX",
    "propname": "When Worlds Collide",
    "territoryname": "Mexico",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "BRZ",
    "propname": "When Worlds Collide",
    "territoryname": "Brazil",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "WIND",
    "propname": "When Worlds Collide",
    "territoryname": "West Indies",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "LA",
    "propname": "When Worlds Collide",
    "territoryname": "Latin America",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "CIS",
    "propname": "When Worlds Collide",
    "territoryname": "CIS",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "UKR",
    "propname": "When Worlds Collide",
    "territoryname": "Ukraine",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "BAL",
    "propname": "When Worlds Collide",
    "territoryname": "Baltic States",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "POL",
    "propname": "When Worlds Collide",
    "territoryname": "Poland",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "CZECH",
    "propname": "When Worlds Collide",
    "territoryname": "Czech Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "SLOV",
    "propname": "When Worlds Collide",
    "territoryname": "Slovak Republic",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "HUNG",
    "propname": "When Worlds Collide",
    "territoryname": "Hungary",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "ALB",
    "propname": "When Worlds Collide",
    "territoryname": "Albania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "CROAT",
    "propname": "When Worlds Collide",
    "territoryname": "Croatia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "SERB",
    "propname": "When Worlds Collide",
    "territoryname": "Serbia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "SLV",
    "propname": "When Worlds Collide",
    "territoryname": "Slovenia",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "ROM",
    "propname": "When Worlds Collide",
    "territoryname": "Romania",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "BUL",
    "propname": "When Worlds Collide",
    "territoryname": "Bulgaria",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "ISR",
    "propname": "When Worlds Collide",
    "territoryname": "Israel",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "MEMIP",
    "propname": "When Worlds Collide",
    "territoryname": "Middle East",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "TUR",
    "propname": "When Worlds Collide",
    "territoryname": "Turkey",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  },
  {
    "propno": "WHENWORLDS",
    "territorycd": "REPSOAFR",
    "propname": "When Worlds Collide",
    "territoryname": "South Africa",
    "library": "Fake Films",
    "hd": "No",
    "production_dates": "",
    "directors_string": "Director(s): Rudolph Mate",
    "directors_len": 25,
    "directors": "Director(s): Rudolph Mate",
    "producers_string": "Producer(s): ",
    "producers_len": 13,
    "producers": "",
    "directors_producers": "Director(s): Rudolph Mate\\x0a",
    "actors": "Richard Derr",
    "genre": "Barbara Rush",
    "right1": "Peter Hansen",
    "right2": "Fantasy",
    "right3": "Science Fiction",
    "right4": "Action-Adventure",
    "right5": "Thriller",
    "right6": "New Release",
    "right7": "New Release",
    "right8": "New Release",
    "right9": "New Release",
    "right10": "New Release",
    "synopsis": "New Release",
    "filename": "New Release",
    "runtimedesc": "",
    "ratingdesc": "",
    "imdblink": "",
    "websiteurl": "As a new star and planet hurtle toward a doomed Earth",
    "releaseyear": "a small group of survivalists frantically work to complete the rocket which will take them to their new home.",
    "notes": "WhenWorldsCollide_thumb.jpg"
  }

]
