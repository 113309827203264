export default {
  id: 1,
  name: 'Greyman Matternski',
  email: 'jacob@fishmyer.com',
  licenses: [
    { filmName: 'The End', expiringIn: '3 years' }
  ],
  films: [
    { name: 'Gorilla and the Apes' }
  ],
  preferences: [
    { name: 'Show all Films', key: 'showAllFilms', value: true },
    { name: 'Filter bad Films', key: 'filterBadFilms', value: false },
  ],
  royalties: [],
}
