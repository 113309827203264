import { call, put, takeLatest } from 'redux-saga/effects'

import AuthAPI from 'api/mock/auth'
import notify from 'utils/notify'
import { isAuthedRoute, routes } from 'config/routes'
import {
  AUTH_REQUIRE_PASSWORD_ACTION,
  AUTH_REQUIRE_PASSWORD_SUCCEEDED_ACTION,
  AUTH_REQUIRE_PASSWORD_FAILED_ACTION,
  LOGIN_ACTION,
  LOGIN_FAILED_ACTION,
  LOGIN_SUCCEEDED_ACTION,
  LOGOUT_ACTION,
  USER_PASSWORD_UPDATE_ACTION,
  USER_PASSWORD_UPDATE_FAILED_ACTION,
  USER_PASSWORD_UPDATE_SUCCEEDED_ACTION,
  USER_UPDATE_ACTION,
  USER_UPDATE_FAILED_ACTION,
  USER_UPDATE_SUCCEEDED_ACTION,
  USER_GET_AUTHED_ACTION,
  USER_GET_AUTHED_SUCCEEDED_ACTION,
  USER_GET_AUTHED_FAILED_ACTION,
} from 'config/constants'

function* getAuthedUserSaga(action) {
  try {
    const result = yield call(AuthAPI.getAuthedUser, action.payload)
    yield put({ type: USER_GET_AUTHED_SUCCEEDED_ACTION, payload: result })
  } catch (e) {

    if (isAuthedRoute()) {
      notify
        .countdownError('We weren\'t able to find your auth credentials. Hang on, we will restart your session in %s seconds')
        .then(() => {
          window.location.pathname = routes.unauthed.logout.path
        })
    }

    yield put({ type: USER_GET_AUTHED_FAILED_ACTION, payload: e.message })
  }
}

function* loginSaga(action) {
  try {
    const result = yield call(AuthAPI.login, action.payload.email, action.payload.password)
    window.localStorage.setItem('auth_token', JSON.stringify(action.payload))
    yield put({ type: LOGIN_SUCCEEDED_ACTION, payload: result })

    setTimeout(() => window.location.href = routes.authed.main.path)
  } catch (e) {
    notify.error('Invalid credentials')
    yield put({ type: LOGIN_FAILED_ACTION, payload: e.message })
  }
}

function logoutSaga(action) {
  localStorage.removeItem('auth_token')
  window.location.href = routes.unauthed.login.path
}

function* requirePasswordSaga(action) {
  try {
    const password = yield call(notify.requirePassword)
    yield call(AuthAPI.verifyPassword, password)
    yield put({ type: AUTH_REQUIRE_PASSWORD_SUCCEEDED_ACTION, payload: action.payload })
  } catch (e) {
    yield put({ type: AUTH_REQUIRE_PASSWORD_FAILED_ACTION, payload: action.payload })
  }
}

function* updateUserSaga(action) {
  // only run if they explicitly persist
  if ( ! action.persist) return false

  try {
    const result = yield call(AuthAPI.updateUser, action.payload)
    yield put({ type: USER_UPDATE_SUCCEEDED_ACTION, payload: result })
  } catch (e) {
    notify.error('Failed to update your information, please try again later')
    yield put({ type: USER_UPDATE_FAILED_ACTION, payload: e.message })
  }
}

function* updateUserPasswordSaga(action) {
  try {
    const result = yield call(AuthAPI.updateUserPassword, action.payload)
    yield put({ type: USER_PASSWORD_UPDATE_SUCCEEDED_ACTION, payload: result })
  } catch (e) {
    yield put({ type: USER_PASSWORD_UPDATE_FAILED_ACTION, payload: e.message })
  }
}

function* authSaga() {
  yield takeLatest(AUTH_REQUIRE_PASSWORD_ACTION, requirePasswordSaga)
  yield takeLatest(LOGIN_ACTION, loginSaga)
  yield takeLatest(LOGOUT_ACTION, logoutSaga)
  yield takeLatest(USER_GET_AUTHED_ACTION, getAuthedUserSaga)
  yield takeLatest(USER_PASSWORD_UPDATE_ACTION, updateUserPasswordSaga)
  yield takeLatest(USER_UPDATE_ACTION, updateUserSaga)
}

export default authSaga;
