import {
  INSTAGRAM_RECENT_ACTION,
} from 'config/constants'

export const recent = () => ({
  type: INSTAGRAM_RECENT_ACTION,
  payload: null,
})

export default {
  recent,
}
