import React from 'react'
import { connect } from 'react-redux'
import { Input, Icon, Button, Form } from 'antd'

import AuthActions from 'actions/Auth'

import 'controllers/Login.scss'

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(AuthActions.login(email, password)),
})

class Login extends React.Component {
  state = {
    email: '',
    password: '',
  }

  submit() {
    const { email, password } = this.state
    const { login } = this.props
    login(email, password)
  }

  render() {
    return (
      <div id='login-controller' className='controller'>
        <div className='album-cover'></div>
        <h1>Ellie Mae</h1>
        <Form className='login-form'>
          <div style={{marginBottom: '16px'}}>
            <Form.Item>
              <Input
                prefix={<Icon type='lock' />}
                type='password'
                placeholder='password'
                onChange={event => this.setState({ password: event.target.value })}
              />
            </Form.Item>
          </div>
          <Button onClick={event => this.submit()}>Submit</Button>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
