import { combineReducers } from 'redux'

import App from 'reducers/App'
import Audio from 'reducers/Audio'
import Auth from 'reducers/Auth'
import Films from 'reducers/Films'
import Instagram from 'reducers/Instagram'
import Preferences from 'reducers/Preferences'
import UI from 'reducers/UI'

export default combineReducers({
  App,
  Audio,
  Auth,
  Films,
  Instagram,
  Preferences,
  UI,
})
