import {
  AUDIO_PAUSE_ACTION,
  AUDIO_PLAY_TRACK_ACTION,
  AUDIO_PLAYLIST_LOADED_ACTION,
  AUDIO_SOUND_CLOUD_INITIALIZED_ACTION,
} from 'config/constants'

export const playTrack = trackId => ({
  type: AUDIO_PLAY_TRACK_ACTION,
  payload: trackId,
})

export const pause = () => ({
  type: AUDIO_PAUSE_ACTION,
})

export const loadPlaylist = playlist => ({
  type: AUDIO_PLAYLIST_LOADED_ACTION,
  payload: playlist,
})

export const soundCloudInitialized = soundCloudAudio => ({
  type: AUDIO_SOUND_CLOUD_INITIALIZED_ACTION,
  payload: soundCloudAudio,
})

export default {
  loadPlaylist,
  pause,
  playTrack,
  soundCloudInitialized,
}
