import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from './Root';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Root>
  <App
    clientId={'370a488dcc177c15e06d89bc637b814b'}
    resolveUrl={'https://soundcloud.com/user-687030344/sets/mind-meadows-master/s-MB2E8'}
  />
</Root>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
