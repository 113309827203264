import {
  AUDIO_PAUSE_ACTION,
  AUDIO_PLAY_TRACK_ACTION,
  AUDIO_PLAY_NEXT_TRACK_ACTION,
  AUDIO_PLAYLIST_LOADED_ACTION,
  AUDIO_SOUND_CLOUD_INITIALIZED_ACTION,
} from 'config/constants'

const initialState = {
  playlist: null,
  playing: false,
  ready: false,
  showPlayer: false,
  soundCloudAudio: null,
  trackId: null,
}

export default (state = initialState, action) => {
  switch (action.type) {

    case AUDIO_PLAYLIST_LOADED_ACTION:
      return {
        ...state,
        playlist: action.payload,
        ready: state.soundCloudAudio && action.payload,
      }

    case AUDIO_PAUSE_ACTION:
      pauseTrack(state.soundCloudAudio, state.playlist, action.payload)

      return {
        ...state,
        playing: false,
        showPlayer: false,
        trackId: null,
      }

    case AUDIO_PLAY_TRACK_ACTION:
      playTrack(state.soundCloudAudio, state.playlist, action.payload)

      return {
        ...state,
        playing: true,
        showPlayer: true,
        trackId: action.payload,
      }

    case AUDIO_PLAY_NEXT_TRACK_ACTION:
      const nextTrackId = nextTrackById(state.playlist, state.trackId).id
      playTrack(state.soundCloudAudio, state.playlist, nextTrackId)

      return {
        ...state,
        trackId: nextTrackId,
      }

    case AUDIO_SOUND_CLOUD_INITIALIZED_ACTION:
      return {
        ...state,
        soundCloudAudio: action.payload,
        ready: state.playlist && action.payload,
      }

    default:
      return state
  }
}

const trackIndexById = (playlist, trackId) => {
  let trackIndex = null

  playlist.tracks.map((track, index) => {
    if (track.id === trackId) trackIndex = index
  })

  return trackIndex
}

const nextTrackById = (playlist, trackId) => {
  let trackIndex = trackIndexById(playlist, trackId) + 1

  if (trackIndex >= playlist.tracks.length) trackIndex = 0

  return playlist.tracks[trackIndex]
}

const nextTrackIndexById = (playlist, trackId) => {
  let trackIndex = trackIndexById(playlist, trackId) + 1

  if (trackIndex > playlist.tracks.length)
    return 0

  return trackIndex
}

const playTrack = (soundCloudAudio, playlist, trackId) => {
  soundCloudAudio.play({ playlistIndex: trackIndexById(playlist, trackId) });
}

const pauseTrack = (soundCloudAudio, playlist) => {
  soundCloudAudio.pause()
}
