import { call, put, takeLatest } from 'redux-saga/effects'

import InstagramAPI from 'api/Instagram'
import {
  INSTAGRAM_RECENT_ACTION,
  INSTAGRAM_RECENT_SUCCEEDED_ACTION,
  INSTAGRAM_RECENT_FAILED_ACTION,
} from 'config/constants'

function* recentSaga(action) {
  try {
    const result = yield call(InstagramAPI.recent)
    yield put({ type: INSTAGRAM_RECENT_SUCCEEDED_ACTION, payload: result })
  } catch (e) {
    yield put({ type: INSTAGRAM_RECENT_FAILED_ACTION, payload: e.message });
  }
}


function* instagramSaga() {
  yield takeLatest(INSTAGRAM_RECENT_ACTION, recentSaga);
}

export default instagramSaga;
