import { applyMiddleware, createStore } from "redux"
import createSagaMiddleware from 'redux-saga'

import root from 'reducers/root'
import AuthSagas from 'sagas/Auth'
import FilmSagas from 'sagas/Film'
import AudioSagas from 'sagas/Audio'
import InstagramSagas from 'sagas/Instagram'

const sagaMiddleware = createSagaMiddleware()
export default createStore(root, applyMiddleware(sagaMiddleware))

sagaMiddleware.run(AuthSagas)
sagaMiddleware.run(FilmSagas)
sagaMiddleware.run(InstagramSagas)
sagaMiddleware.run(AudioSagas)
