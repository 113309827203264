// UI
exports.MIN_WIDTH_FOR_SECONDARY_SIDEBAR             = 1000
exports.MIN_WIDTH_FOR_EXPANDED_SIDEBAR              = 700
exports.SECONDARY_SIDEBAR_WIDTH                     = 280
exports.SIDEBAR_EXPANDED_WIDTH                      = 200
exports.SIDEBAR_MINIMIZED_WIDTH                     = 55

// ACTIONS
exports.AUDIO_PLAY_TRACK_ACTION                     = 'AUDIO::PLAY_TRACK'
exports.AUDIO_PLAY_NEXT_TRACK_ACTION                = 'AUDIO::PLAY_NEXT_TRACK'
exports.AUDIO_PAUSE_ACTION                          = 'AUDIO::PAUSE'
exports.AUDIO_SOUND_CLOUD_INITIALIZED_ACTION        = 'AUDIO::SOUND_CLOUD_INITIALIZED'
exports.AUDIO_PLAYLIST_LOADED_ACTION                = 'AUDIO::PLAYLIST_LOADED'
exports.AUTH_REQUIRE_PASSWORD_ACTION                = 'AUTH::REQUIRE_PASSWORD'
exports.AUTH_REQUIRE_PASSWORD_SUCCEEDED_ACTION      = 'AUTH::REQUIRE_PASSWORD_SUCCEEDED'
exports.AUTH_REQUIRE_PASSWORD_FAILED_ACTION         = 'AUTH::REQUIRE_PASSWORD_FAILED'
exports.AUTH_CHECK_ACTION                           = 'APP::AUTH_CHECK'
exports.FILM_GET_ACTION                             = 'FILM::GET_ACTION'
exports.FILM_GET_FAILED_ACTION                      = 'FILM::GET_FAILED'
exports.FILM_GET_SUCCEEDED_ACTION                   = 'FILM::GET_SUCCEEDED'
exports.INSTAGRAM_RECENT_ACTION                     = 'INSTAGRAM::RECENT'
exports.INSTAGRAM_RECENT_SUCCEEDED_ACTION           = 'INSTAGRAM::RECENT_SUCCEEDED'
exports.INSTAGRAM_RECENT_FAILED_ACTION              = 'INSTAGRAM::RECENT_FAILED'
exports.LOGIN_ACTION                                = 'AUTH::LOGIN'
exports.LOGIN_FAILED_ACTION                         = 'AUTH::LOGIN_FAILED'
exports.LOGIN_SUCCEEDED_ACTION                      = 'AUTH::LOGIN_SUCCEEDED'
exports.LOGOUT_ACTION                               = 'AUTH::LOGOUT'
exports.REDIRECT_ACTION                             = 'APP::REDIRECT'
exports.UI_SHOW_SECONDARY_SIDEBAR_ACTION            = 'UI::SHOW_SECONDARY_SIDEBAR'
exports.UI_HIDE_SECONDARY_SIDEBAR_ACTION            = 'UI::HIDE_SECONDARY_SIDEBAR'
exports.UI_SET_DIMENSIONS_ACTION                    = 'UI::SET_DIMENSIONS'
exports.UI_SET_SCROLL_TOP_ACTION                    = 'UI::SET_SCROLL_TOP'
exports.UI_SET_THEME_ACTION                         = 'UI::SET_THEME'
exports.USER_PASSWORD_UPDATE_ACTION                 = 'AUTH::USER_PASSWORD_UPDATE'
exports.USER_PASSWORD_UPDATE_SUCCEEDED_ACTION       = 'AUTH::USER_PASSWORD_UPDATE_SUCCEEDED'
exports.USER_PASSWORD_UPDATE_FAILED_ACTION          = 'AUTH::USER_PASSWORD_UPDATE_FAILED'
exports.USER_GET_AUTHED_ACTION                      = 'AUTH::USER_GET_AUTHED'
exports.USER_GET_AUTHED_FAILED_ACTION               = 'AUTH::USER_GET_AUTHED_FAILED'
exports.USER_GET_AUTHED_SUCCEEDED_ACTION            = 'AUTH::USER_GET_AUTHED_SUCCEEDED'
exports.USER_UPDATE_ACTION                          = 'AUTH::USER_UPDATE'
exports.USER_UPDATE_FAILED_ACTION                   = 'AUTH::USER_UPDATE_FAILED'
exports.USER_UPDATE_SUCCEEDED_ACTION                = 'AUTH::USER_UPDATE_SUCCEEDED'

// PAGE CONSTANTS
exports.PREFERENCES_CHANGE_PASSWORD                 = 'PREFERENCES::CHANGE_PASSWORD'
