import React from 'react'

import './Video.scss'

export default () => {
  return (
    <div
      className='video'
    >
      <iframe
        title='Disco Bugs (Demo)'
        src="https://player.vimeo.com/video/430870553"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
    </div>
  )
}
