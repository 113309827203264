import {
  UI_HIDE_SECONDARY_SIDEBAR_ACTION,
  MIN_WIDTH_FOR_EXPANDED_SIDEBAR,
  UI_SHOW_SECONDARY_SIDEBAR_ACTION,
  UI_SET_THEME_ACTION,
  UI_SET_DIMENSIONS_ACTION,
  UI_SET_SCROLL_TOP_ACTION,
} from 'config/constants'

const initialState = {
  defaultMinimizeSidebar: false,
  dimensions: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  scrollTop: 0,
  secondarySidebarMode: 'TreeAnalysis',
  showSecondarySidebar: false,
  theme: 'light',
}

export default function(state = initialState, action) {
  switch (action.type) {

    case UI_SHOW_SECONDARY_SIDEBAR_ACTION:
      return {
        ...state,
        showSecondarySidebar: true,
        secondarySidebarMode: action.payload.mode || state.secondarySidebarMode || 'TreeAnalysis'
      }

    case UI_HIDE_SECONDARY_SIDEBAR_ACTION:
      return {
        ...state,
        showSecondarySidebar: false,
      }

    case UI_SET_THEME_ACTION:
      // set at root for theming modals
      document.body.setAttribute('data-theme', action.payload)

      return {
        ...state,
        theme: action.payload,
      }

    case UI_SET_DIMENSIONS_ACTION:
      const dimensions = action.payload

      return {
        ...state,
        dimensions,
        defaultMinimizeSidebar: dimensions.width < MIN_WIDTH_FOR_EXPANDED_SIDEBAR
      }

    case UI_SET_SCROLL_TOP_ACTION:
      return {
        ...state,
        scrollTop: action.payload,
      }

    default:
      return state
  }
}
